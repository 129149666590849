import React from 'react';
import { Button } from '../../../../retro';
import './Approval.css'
import {Headings} from '../../../../retro';
import moment from 'moment';
import '../../../../retro/Animation/Animation.css'

function ApprovalCard({id, permissionType, status, title, reference, details, image, asker, createdAt, instanceType, actions, onApprove, onDecline, onClick}) {

    return (
        <div onClick={onClick} className='FadeInUp-Effect approval-card'>
            <div>
                <Headings.Regular className='text-truncate'>
                    {
                        instanceType==='workflow'?
                        <i className="fas fa-sitemap mr1"></i>
                        :<></>
                    }
                    {title}
                </Headings.Regular>
                <div className='fw-bold text-truncate text-small'>{reference&&reference}</div>
                <div className='mt1 mb1' style={{borderBottom: '1px solid var(--theme-border-color'}}/>
                <div className='text-truncate text-small'>{details&&details}</div>
                <div className='text-truncate text-small'>Asked on: {moment(createdAt).format('DD MMM YY')}</div>
                <div className='text-truncate text-small'>Asked by: {asker}</div>
                <div className='flex justify-between align-center text-small mt2'>
                    {
                        status&&
                        <div className={status==='COMPLETED'?'success-pill':status==='DECLINED'?'danger-pill':'text-pill'} style={{fontSize: '0.6rem'}}>{status}</div>
                    }
                    <div><i className="fas fa-external-link-alt"/> Open</div>
                </div>
            </div>
            {
                actions&&
                <div className='mt1 flex center'>
                    <Button onClick={e=>{e.stopPropagation();onApprove()}} className='approve-btn btn-sm mr1 flex-fill' margin='mt1'><i className="fas fa-check"/> Approve</Button>
                    <Button onClick={e=>{e.stopPropagation();onDecline()}} className='decline-btn btn-sm flex-fill' margin='mt1'><i className="fas fa-times"/> Decline</Button>
                </div>
            }
        </div>
    );
}

export default ApprovalCard;