import React from 'react';

function Insights({tags,items}) {
    return (
        <>
        <div className='fw-bold mb3 text-small'>INSIGHTS</div>
        {
            Object.entries(tags).map((tag, index)=>{
            return (
                <div key={index} className='flex align-center mt2 text-small' style={{overflowWrap: 'break-word'}}>
                    <span style={{textTransform: 'uppercase'}}>{tag[0]}</span>
                    <i className="fas fa-chevron-right ml1 mr3"/>
                    <span>{tag[1].substring(0, 4) === 'http' ? <></> : tag[1]}</span>
                </div>
            )
            })
        }
         {
            items.filter(item=>item.data.length>0).map((item,index)=>{
                return(
                    <div key={index} className="mt3" style={{borderTop:'1px solid var(--theme-border-color)'}}>
                        <div className='fw-bold mb3 mt3 text-small'>{item.name}</div>
                        {
                            item.data.map((i,index1)=>{
                                return(
                                    <div key={index1} className='flex align-center mt2 text-small' style={{overflowWrap: 'break-word'}}>
                                        <span style={{textTransform: 'uppercase'}}>{i.key}</span>
                                        <i className="fas fa-chevron-right ml1 mr3"/>
                                        <span>{i.value}</span>
                                     </div>
                                )
                            })
                        }
                    </div>
                )
            })
         }
        </>
    );
}


Insights.defaultProps={
    items:[]
}
export default Insights;