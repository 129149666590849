import React, {useId, useState} from 'react'
import {Document, Page, pdfjs} from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import {Shimmer, Texts} from "../index";
import Open from './open.png'
import Download from './download.png'

pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js"

export default function PdfViewer({url,width= 500}){
	const id = useId()
	const [numPages, setNumPages] = useState(undefined);
	const [pageNumber, setPageNumber] = useState(1);
	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
	}
	return (
		<div style={{
			width,
			overflow:'hidden'
		}} className="relative border rounded-sm">
			<div style={{height:30,background:'var(--theme-primary-container)'}} className="pdf-toolbox flex horizontally center-vertically pd1 ">
				{
					width>400&&<Texts.Small>
						{url.substring(url.lastIndexOf('/')+1)}
					</Texts.Small>
				}
				<div className="flex horizontally center-vertically" style={{
					flex:1,
					flexDirection:'row-reverse'
				}}>
					<div style={{cursor:'pointer'}} onClick={()=>{
						window.open(url,"_blank")
					}} className="ml2 horizontally flex">
						<img src={Open} alt="open icon" style={{width:14}}/>
					</div>
					<div style={{cursor:'pointer'}} onClick={()=>{
						fetch(url).then(response => {
							response.blob().then(blob => {
								const fileURL = window.URL.createObjectURL(blob);
								let alink = document.createElement('a');
								alink.href = fileURL;
								alink.download = url.substring(url.lastIndexOf('/')+1);
								alink.click();
							})
						})
					}} className="ml2 horizontally flex">
						<img src={Download} alt="open icon" style={{width:14}}/>
					</div>
					{
						width>300&&<Texts.Small>
							<span>Page {pageNumber} of {numPages}</span>
						</Texts.Small>
					}
				</div>
			</div>
			<div id={id}>
				<Shimmer width={width} height={width*1.5} style={{
					display:numPages===undefined?"block":'none'
				}}/>
				<div style={{
					display:numPages!==undefined?"block":'none'
				}}>
					<Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
						<Page width={width-2}  pageNumber={pageNumber} />
					</Document>
				</div>
			</div>
			<div className="flex horizontally center-vertically pd2 bg-white shadow-md rounded-md" style={{
				position:'absolute',
				left:'calc(50% - 50px)',
				width:'100px',
				display:width>300?'block':'none',
				bottom:20,
				zIndex:11
			}}>
				<button onClick={()=>{
					if(pageNumber>1){
						setPageNumber(pageNumber-1)
					}
				}} style={{
					background:'none',
					fontSize:16,
					cursor:'pointer',
					border:'none',
					marginRight:12
				}} disabled="" type="button">‹</button>
				<span>{pageNumber} of {numPages}</span>
				<button  onClick={()=>{
					if(pageNumber<numPages){
						setPageNumber(pageNumber+1)
					}
				}} style={{
					background:'none',
					fontSize:16,
					border:'none',
					cursor:'pointer',
					marginLeft:12
				}} type="button">›</button>
			</div>
			{
				width<400&&
					<p className="truncate" style={{
						position:'absolute',
						left:'10%',
						bottom:0,
						padding:'4px',
						background:'rgba(255,255,255,.4)',
						fontSize:9,
						width:'80%',
						zIndex:11
					}}>
						{url.substring(url.lastIndexOf('/')+1)}
					</p>
			}
		</div>
	)
}
