import React, {useEffect, useState} from 'react';
import InvoiceIndia from '../../../../Components/Invoice/India/Preview'
import InvoiceOther from '../../../../Components/Invoice/Other/Preview'
import {Switch} from '../../../../../retro'
import {get as getCrypto, set as setCrypto} from '../../../../Utils/Crypto'

function PreviewAttachment({details,invoiceId,refresh}) {

    const [Preview, setPreview] = useState(getCrypto('PreviewAttachment'));

    useEffect(() => {
        setCrypto('PreviewAttachment', Preview)
    }, [Preview]);

    const renderFiles = (file, index) => {
        if(file.endsWith('.pdf')){
            return <iframe onClick={()=>window.open(file,'_blank')} key={index} src={file} title='Preview' className='pointer mt1' style={{border: 'none', width:'100%', height: '100vh'}}/>
        }else{
            return <img onClick={()=>window.open(file,'_blank')} src={file} alt='Attachment' className='w-100 mt1 pointer'/>
        }
    }

    return (
        <div className='w-100 text-center'>
            <div className='flex center pd3' style={{justifyContent: 'end'}}>
                <div className='text-small text-muted mr3'>Preview Attachments</div>
                <Switch state={Preview} onChange={(bool)=>setPreview(bool)} label='Preview Attachment'/>
            </div>
            {
                Preview&&details.invoice&&details.invoice.files.length
                ?<div className='flex column w-100'>
                    {details.invoice.files.map(renderFiles)}
                </div>
                :details.renderer==='INDIA'?<InvoiceIndia {...details} invoiceId={invoiceId} refresh={refresh}/>:<InvoiceOther {...details}/>
            }
        </div>
    );
}

export default PreviewAttachment;