import React, {useState, useEffect} from 'react';
import {Headings, Grids, PageLoad, SearchBox, Loaders} from "../../../../retro";
import Viewer from './Viewer'
import moment from 'moment';
import {get} from '../../../Network/Axios'
import './index.css'
import '../../../../retro/Animation/Animation.css'
import MailIcon from '../../../Assets/SVG/email.svg'
import {useHistory, useLocation} from 'react-router-dom'

function Inbox() {

    let HISTORY = useHistory();
    let LOCATION = useLocation()
    let pathname = LOCATION.pathname;
    let params = new URLSearchParams(LOCATION.search)
	let mailId = params.get('mail')

    const [Loading, setLoading] = useState(true);
    const [Mails, setMails] = useState([]);
    const [FilteredMails, setFilteredMails] = useState([]);
    const [Total, setTotal] = useState();

    useEffect(() => {
        get(`inbox/mails`, (e,r)=>{
            if(r){
                setMails(r.messages);
                setFilteredMails(r.messages);
                setTotal(r.total);
                setLoading(false);
                PageLoad('Inbox')
            }else if(e){
    
            }
        }, {offset: 0})
        
        let Interval = setInterval(() => {
            reload()
        }, 5000);

    return ()=>{
        clearInterval(Interval);
    }
    }, []);

    const reload = ()=>{
        get(`inbox/mails`, (e,r)=>{
            if(r){
                setMails(r.messages);
                setTotal(r.total);
                setLoading(false);
                PageLoad('Inbox')
            }else if(e){
    
            }
        }, {offset: 0})
    }

    const renderMails = (item, index)=>{
        return (
            <div key={index} onClick={()=>{HISTORY.push(`${pathname}?mail=${item.id}`)}} className={`FadeIn-Effect ${mailId===item.id?'mail-tile-active':item.read?'mail-tile':'mail-tile-unread'}`}>
                <div className='flex justify-between'>
                    <div className='text-truncate'>From: {item.from}</div>
                    <div className='text-truncate'>{moment(item.createdAt).format(" hh:mm a DD MMM YY")}</div>
                </div>
                <div className='subject text-truncate'>{item.subject}</div>
                <div className='text-truncate mt1'>{item.attachments} Attachment</div>
            </div>
        )
    }

    const filterMails = (val) => {
        val = val.toLowerCase();
        if(val){
            let filtered = Mails.filter((mail)=>(mail.from.toLowerCase().includes(val)||mail.subject.toLowerCase().includes(val)))
            setFilteredMails(filtered)
        }else{
            setFilteredMails(Mails)
        }
    }

    return (
        <>
		<div className='flex flex-wrap h-100 pt1'>
            <Grids.Column grid={{mobile:12, medium: 5, large: 5}}>
                <div className='mails_list_header pr-md-3 pl6 pl-md-0'>
                    <Headings.Small>{Total} Mails</Headings.Small>
                    <div className='text-small pointer' onClick={()=>{setLoading(true);reload()}}>
                        <i className="fas fa-undo mr1"/>
                        Refresh
                    </div>
                </div>
                <SearchBox placeholder='Search' onChange={filterMails}/>
                <div className='text-center overScroll pt2'>
                    {   Loading?<Loaders.Medium/>:
                        FilteredMails.length
                        ?FilteredMails.map(renderMails)
                        :<div className='pd3 w-100 border rounded-sm text-small text-muted text-center'>You have no Mails</div>
                    }
                </div>
            </Grids.Column>
            <Grids.Column grid={{mobile:12, medium: 7, large: 7}} className='pl-md-2' style={{maxHeight:'100%'}}>
                {
                mailId?
                <div className='FadeInUp-Effect mail-viewer'>
                    <Viewer mailID={mailId} onClose={()=>HISTORY.push(pathname)}/>
                </div>
                :<div className='mail-placeholder border rounded-sm pd3'>
                    <img src={MailIcon} alt='Icon' style={{maxWidth:80}}/><br/>
                    Select an Email to View
                </div>
                }
            </Grids.Column>
        </div>
		</>
    );
}

export default Inbox;