import React, {useRef, useEffect} from 'react'
import {Texts, Headings, Button, Grids, Navigation, Input, TOAST, PageLoad, UserAction} from "../../../../retro";
import { post } from '../../../Network/Axios';

function Settings() {

	let PASS = useRef(), CONFIRM = useRef();

	useEffect(() => {
		PageLoad('Settings');
	}, []);

	const changePassword = (params) => {
		let password = PASS.current.value;
		let confirm = CONFIRM.current.value;

		if(!password){
			TOAST.error('Enter Password')
			return
		}
		if(!confirm){
			TOAST.error('Enter Confirm Password')
			return
		}
		if(password!==confirm){
			TOAST.error('Passowrd do not match')
			return
		}

		post('config/password',{password: confirm},(e,r)=>{
			if(r){
				TOAST.success('Password Changed')
				PASS.current.value = ''
				CONFIRM.current.value = ''
				UserAction('Password Change')

			}else if(e){
				TOAST.handleError(e)
			}
		})

	}

	return (
		<>
		<Navigation title='Account Settings' backUrl='/' chips={['Settings']} />
		<div>
			<Grids.Row>
				<Grids.Column grid={{mobile:12, medium: 6, large: 6}} className='mt3 mt-md-5'>
					<div className='bg-white rounded-md pd4 border'>
						<Headings.Regular>Change Password</Headings.Regular>
						<Texts.Regular>You can change your account password here</Texts.Regular>
						<Input morph={true} type='text' ref={PASS} label='New Password' placeholder='Enter Password'/>
						<Input morph={true} type='password' ref={CONFIRM} label='Confirm Password' placeholder='Confirm Password'/>
						<Button onClick={changePassword} className='btn-black mt4 mr1'>Update Password</Button>
						<Button onClick={()=>{window.localStorage.clear();window.location.reload()}} className='btn-secondary mt4'>Logout</Button>
					</div>
				</Grids.Column>
			</Grids.Row>
		</div>
		</>
	);
}

export default Settings;