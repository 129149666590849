import React, {useState, useEffect} from 'react';
import SearchBox from '../../../../../retro/Inputs/SearchBox';
import {get} from '../../../../Network/Axios'
import {TOAST, Empty, Loaders} from '../../../../../retro'
import ApprovalCard from '../../../../Components/Cards/Approval/Approval';
import ViewApproval from '../View'
import { useHistory, useLocation } from 'react-router-dom';

function Pending() {

    let HISTORY = useHistory();
    let LOCATION = useLocation();
    let pathname = LOCATION.pathname;
    let params = new URLSearchParams(LOCATION.search)
	let approvalId = params.get('approval')

    const [Approvals, setApprovals] = useState([]);
    const [FilteredApprovals, setFilteredApprovals] = useState([]);

    const [Loading, setLoading] = useState(true);

    useEffect(() => {
        fetchApprovals()
    }, []);

    const fetchApprovals = () => {
        get(`/workflow/pending`, (e,r)=>{
            if(r){
                setLoading(false)
                setApprovals(r.approvals.sort((a,b)=>(b.createdAt-a.createdAt)))
                setFilteredApprovals(r.approvals)
            }else if(e){
               TOAST.handleError(e)
            }
        })
    }

    const searchApprovals = (val) => {
        val = val.toLowerCase();
        if(val){
            let filtered = Approvals.filter((item)=>(item.title.toLowerCase().includes(val) || item.asker.toLowerCase().includes(val) || item.details.toLowerCase().includes(val)))
            setFilteredApprovals(filtered)
        }else{
            setFilteredApprovals(Approvals)
        }
    }

    return (
        <>
        <SearchBox placeholder='Search' onChange={searchApprovals}/>
        <div className='flex flex-wrap mt3 w-100'>
            {
                Loading?<div className='w-100 text-center'><Loaders.Medium/></div>:
                FilteredApprovals.length?
                FilteredApprovals.map((approval, index)=>{
                    return (
                        <ApprovalCard onClick={()=>HISTORY.push(`${pathname}?approval=${approval.id}`)} key={index} {...approval}/>
                    )
                })
                :<Empty label='No Approvals found'/>
            }
        </div>
        {approvalId&&<ViewApproval onReload={()=>{HISTORY.push(pathname);fetchApprovals()}} id={approvalId} onClose={()=>HISTORY.push(pathname)}/>}
        </>
    );
}

export default Pending;