import {get, set} from "../../Utils/Crypto";
import Moment from 'moment'

const AuthActions = {
	Login: "Auth_Login",
	Logout: "Auth_Logout",
	Color: "COLOR",
	Config: "Auth_Config"
}

let emptyState = {
	authenticated: false,
	config: {
		loading : true,
		color:"#6367FA"
	},
	lastLogin: undefined
}

const KEYS = {
	LOGGEDIN : "dice-scan-login-in",
	NAME:"dice-scan-name",
	LOGINTIME:"dice-scan-last"
}

const initialState = () => {
	let color = get("default-color");
	if(!color)
		color ="#6367FA";
	const loggedIn = get(KEYS.LOGGEDIN);
	if (loggedIn && loggedIn === "yes") {
		return {
			...emptyState, authenticated: true, name: get(KEYS.NAME), lastLogin: Moment(),
			config: {
				loading : true,
				color
			}
		}
	} else
		return emptyState;
}


export const auth = (state = initialState(), action) => {
	let data = action.data;
	switch (action.type) {
		case AuthActions.Color:
			set("default-color",data)
			return {
				...state, config: {
					...state.config,
					color:data
				}
			}
		case AuthActions.Logout:
			return initialState();
		case AuthActions.Login:
			return {
				...state,
				authenticated: true,
				name: data.name,
				lastLogin: data.login
			}
		case AuthActions.Config:
			state = {
				...state, config: {
					...state.config,
					...data,
					loading:false
				}
			}
			return state;
		default:
			return state;
	}
}

export {
	AuthActions
}
