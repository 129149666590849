import React, {useState, useEffect} from 'react';
import SearchBox from '../../../../../retro/Inputs/SearchBox';
import {get} from '../../../../Network/Axios'
import {TOAST, Empty, PageLoad, Loaders} from '../../../../../retro'
import FileCard from '../../../../Components/Cards/File/File'

function Pending() {

    ;
    const [Files, setFiles] = useState([]);
    const [FilteredFiles, setFilteredFiles] = useState([]);
    const [Loading, setLoading] = useState(true);

    useEffect(() => {
        get(`/scan/pending`, (e,r)=>{
            if(r){
                setLoading(false)
                setFiles(r.scans)
                setFilteredFiles(r.scans)
                PageLoad('Uploaded Scans')
            }else if(e){
               TOAST.handleError(e)
            }
        },{offset: 0})
    }, []);

    const searchFiles = (val) => {
        val = val.toLowerCase();
        if(val){
            let filtered = Files.filter((file)=>(file.name.toLowerCase().includes(val)))
            setFilteredFiles(filtered)
        }else{
            setFilteredFiles(Files)
        }
    }

    return (
        <>
        <SearchBox placeholder='Search' onChange={searchFiles}/>
        <div className='flex flex-wrap mt3 w-100'>
            {
                Loading?<div className='w-100 text-center'><Loaders.Medium/></div>:
                FilteredFiles.length?
                FilteredFiles.map((file, index)=>{
                    return (
                        <FileCard onClick={()=>window.open(file.file,'_blank')} key={index} {...file}/>
                    )
                })
                :<Empty label='No scans found'/>
            }
        </div>
        </>
    );
}

export default Pending;