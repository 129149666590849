import React, {useState, useEffect, useRef} from 'react';
import {Button, Grids, Headings, Navigation, Texts, TOAST, UploadEvent, AttachmentPreview, DropFileInput} from '../../../../retro';
import useConfigHook from '../../../Hooks/useConfigHook';
import SingleLine from '../../../Components/Invoice/India/SingleLine'
import MultiLine from '../../../Components/Invoice/India/MultiLine'
import TickAnimation from '../../../Components/TickAnimation/TickAnimation'
import {post} from '../../../Network/Axios'
import moment from 'moment';

function Invoices() {

    let CONFIG = useConfigHook()
    let INVOICE = useRef()
    const [PreviewData, setPreviewData] = useState({});
    const [Invoice, setInvoice] = useState(undefined);

    const [Success, setSuccess] = useState(undefined);

    useEffect(() => {
    }, [PreviewData]);

    const submitInvoice = () => {
        if(Invoice){
            if(Invoice.type==='multiline'){
                post(`invoices/scan`, {data: Invoice.data}, (e,r)=>{
                    if(r){
                        TOAST.success('Invoice Submitted')
                        UploadEvent('Multiline Invoice', Invoice.data.billNumber)
                        setSuccess(true)
                    }else if(e){
                        TOAST.handleError(e)
                    }
                })
            }else{
                post(`invoices/scan/direct`,{data: Invoice.data},(e,r)=>{
                    if(r) {
                        TOAST.success('Invoice Submitted')
                        UploadEvent('Single Line Invoice', Invoice.data.billNumber)
                        setSuccess(true)
                    }else if(e){
                        TOAST.handleError(e)
                    }
                })
            }
        }
    }

    const lastLook = (data,type) => {
        document.getElementById('wrapper').scroll({ top: 0, behavior: "smooth"})
        setInvoice(data,type)
    }

    if(Success){
        return (
            <div className='FadeInUp-Effect mt4 flex w-100 center' style={{height:'90%'}}>
                <div className='bg-light rounded-md pd5 text-center w-100 w-md-auto'>
                    <TickAnimation color='var(--theme-primary-color)'/>
                    <Headings.Medium>Submitted</Headings.Medium>
                    <Texts.Regular>
                        Invoice submitted successfuly
                    </Texts.Regular>
                    <Button onClick={()=>setInvoice(undefined)} className='btn-black'>Sumbit Another</Button>
                </div>
            </div>
        )
    }

    return (
        <>
        <Navigation title='New Invoice' backUrl='/' chips={['Submit Invoice']} description='Fill the details to submit your Invoice' />
            <Grids.Row className='mt4 bg-white border rounded-md pd4'>
                
                <Grids.Column grid={{mobile: 12, medium: 6, large: 6}} className='pr4'>
                        <div className='relative'>
                        {
                            !CONFIG.multiLine
                            ?<MultiLine update={(func)=>INVOICE.current=func} updatePreview={setPreviewData} onSubmit={(data,type)=>lastLook({data, type})}/>
                            :<SingleLine update={(func)=>INVOICE.current=func} updatePreview={setPreviewData} onSubmit={(data,type)=>lastLook({data, type})}/>
                        }
                        {
                            Invoice&&
                                <div className='absolute bg-white w-100 h-100' style={{top:0, left:0}}>
                                    <Headings.Large>One last look</Headings.Large>
                                    <div className='mt3'>

                                        <div className='bg-light rounded-sm pd2'>
                                            <Headings.Regular style={{fontWeight:'lighter'}}>Invoice of Amount <b>INR {Invoice.data.total}</b> and<br/> Bill Number <b>{Invoice.data.billNumber}</b> </Headings.Regular>
                                        </div>

                                        <Headings.Regular className='mt4' style={{fontWeight:'lighter'}}>Billed on</Headings.Regular>
                                        <Texts.Regular>{moment(Invoice.data.billDate).format('DD MMM YYYY')}</Texts.Regular>
                
                                        <Headings.Regular className='mt2' style={{fontWeight:'lighter'}}>Due on</Headings.Regular>
                                        <Texts.Regular>{moment(Invoice.data.dudeDate).format('DD MMM YYYY')}</Texts.Regular>
                
                                        <Headings.Regular className='mt2' style={{fontWeight:'lighter'}}>Vendor</Headings.Regular>
                                        <Texts.Regular><i className="fas fa-user mr1"/>
                                            {Invoice.data.vendor.title}, {Invoice.data.vendor.gstin}, {Invoice.data.vendor.email}

                                        </Texts.Regular>

                                        <Headings.Regular className='mt2' style={{fontWeight:'lighter'}}>Billed to</Headings.Regular>
                                        <Texts.Regular><i className="fas fa-building mr1"/>
                                            {Invoice.data.billTo.name}, {Invoice.data.billTo.gstin}
                                        </Texts.Regular>
                
                                        <Headings.Regular className='mt2' style={{fontWeight:'lighter'}}>Shipping to</Headings.Regular>
                                        <Texts.Regular><i className="fas fa-truck mr1"/>
                                        {Invoice.data.shipTo.name}, {Invoice.data.shipTo.gstin}
                                        </Texts.Regular>
                                    </div>
                                
                                    <div className='mt3 border-top pt2'>
                                        <Button onClick={()=>setInvoice(undefined)} className='btn btn-secondary mr1'>Edit</Button>
                                        <Button onClick={submitInvoice} className='btn btn-black'>Submit <i className="ml1 fas fa-arrow-right"></i></Button>
                                    </div>
                                </div>
                        }
                        </div>
                </Grids.Column>

                <Grids.Column grid={{mobile: 12, medium: 6, large: 6}} className='display-none display-md overScroll rounded-sm' style={{background:'var(--theme-variant-color)', maxHeight: 'calc(100vh - 14rem)',overflowY:'scroll', position:'sticky', top: '8rem'}}>
                    <div className='h-100 w-100 relative'>
                    {
                        PreviewData&&PreviewData.attachment&&PreviewData.attachment.length
                        ?<div className='pd2'><AttachmentPreview files={PreviewData.attachment}/></div>
                        :<DropFileInput onDrop={(file)=>INVOICE.current({attachments:[file]})}/>

                    }
                    </div>
                </Grids.Column>
            </Grids.Row>
        </>
    );
}

export default Invoices;