import React, {useRef, useState, useEffect} from 'react';
import {Headings, Input, Button, UploadEvent, TOAST, ChipsSelect} from '../../../../retro'
import {post} from '../../../Network/Axios'
import TicketIcon from '../../../Assets/Images/ticket.png'

function AddTicket({onReload, onClose}) {

    let TITLE = useRef()
    const CATEGORIES = ['Technical','Unable to submit Invoice','Inbox','Not receiving approvals', 'Approval not working']
    
    const [Category, setCategory] = useState(undefined);

    const createTicket = () => {

        let title = TITLE.current.value
        if(!title){
            TOAST.error('Enter a title for Ticket')
            return
        }
        
        if(!Category){
            TOAST.error('Select an issue for Ticket')
            return
        }

        post(`/support`, {title, category: Category}, (e,r)=>{
            if(r){
                TOAST.success('Ticket Created')
                setCategory(undefined)
                TITLE.current.value = ''
                
                UploadEvent('Support Ticket',title)
                onReload()
            }else if(e){
                TOAST.error('Problem in opening Mail')
            }
        })
    }

    useEffect(() => {
        console.log(Category)
    }, [Category]);

    return (
        <>
        <div className='FadeInUp-Effect h-100 flex column center relative new-ticket'>
            <div className='w-100 text-center'>
                <img src={TicketIcon} style={{width:'70px'}} alt='Ticket Icon'/>
                <Headings.Regular> Create New Ticket</Headings.Regular>
                <Input ref={TITLE} morph={true} label='Title' placeholder='Title' className='m-auto' style={{maxWidth:'300px', textAlign:'start'}}/>
                <div className='w-100 text-center'>
                    <ChipsSelect align='center' type='single' className='mt3' options={CATEGORIES} selected={Category} onSelect={setCategory} label='Select Issue'/>
                </div>
                <Button onClick={createTicket} className='btn-black rounded-pill' margin='mt4'>
                    Create<i className="fas fa-arrow-circle-right ml1"></i>
                </Button>
            </div>
        </div>
        </>
    );
}

export default AddTicket;