import React, {useEffect, useState} from 'react';
import {Texts, Headings, Button, PageLoad} from "../../../../retro";
import {get} from '../../../Network/Axios'
import { useHistory } from 'react-router-dom';

function Insights({invoices, approvals}) {

        let HISTORY = useHistory();
        const [Stats, setStats] = useState({unread: 0, mail: 0,invoices, approvals});

        useEffect(() => {
            setStats(prev=>({...prev, invoices, approvals}))
        }, [invoices, approvals]);
    
        useEffect(() => {
            get(`/inbox/mails`, (e,r)=>{
                if(r){
                    let unread = 0;
                    r.messages.forEach(msg => {
                        if(!msg.read){
                            unread++;
                        }
                    });
                    setStats(prev=>({...prev, unread, mail: r.total}))
                }
            },{offset: 0})
            PageLoad('Dashboard')
        }, []);
    
    return (
            <div className='mt1 flex flex-wrap scrollbar-hidden' style={{marginLeft: '-0.7rem'}}>
                <div className='pd3 rounded-md margin-1 flex-fill' style={{backgroundColor: '#CEE5D0', width: '150px', flexShrink: 0}}>
                    <Headings.Large>
                        {Stats.unread}
                        <span style={{color: '#94B49F'}}>/{Stats.mail}</span>
                    </Headings.Large>
                    <Texts.Regular className='fw-bold'>
                        <i className="fas fa-envelope mr1"/>
                        Unread Mails
                    </Texts.Regular>
                    <Button onClick={()=>{HISTORY.push('/inbox')}} className={'btn-black w-100'}>Go to Inbox<i className="ml1 far fa-arrow-alt-circle-right"></i></Button>
                </div>
                <div className='pd3 rounded-md margin-1 flex column justify-between flex-fill' style={{backgroundColor: '#F7ECDE', width: '150px', flexShrink: 0}}>
                    <div>
                        <Headings.Large>{Stats.invoices}</Headings.Large>
                        <Texts.Regular className='fw-bold'>
                            <i className="fas fa-receipt mr1"/>
                            Pending Invoices
                        </Texts.Regular>
                    </div>
                    <div onClick={()=>{HISTORY.push('/invoices')}} className='fw-bold pointer mt1'>View all<i className="ml1 far fa-arrow-alt-circle-right"></i></div>
                </div>
                <div className='pd3 rounded-md margin-1 flex column justify-between flex-fill' style={{backgroundColor: '#FEE3EC', width: '150px', flexShrink: 0}}>
                    <div>
                        <Headings.Large>{Stats.approvals}</Headings.Large>
                        <Texts.Regular className='fw-bold'>
                            <i className="fas fa-user-check mr1"></i>
                            Pending Approvals
                        </Texts.Regular>
                    </div>
                    <div onClick={()=>{HISTORY.push('/approvals')}} className='fw-bold pointer mt1'>View all<i className="ml1 far fa-arrow-alt-circle-right"></i></div>
                </div>
            </div>
    );
}

export default Insights;