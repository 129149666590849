import React, {useRef} from 'react';
import axios from "axios";
import {TOAST} from "../../../retro";
import {useLoginHook} from "../../Hooks/useLoginHook";

export default function Index(props) {
	let AuthFrame = useRef(null);
	let RedirectURI = window.location.origin;

	const LOGIN = useLoginHook()

	const startAuth = () => {
		let href = null;
		try {
			href = AuthFrame.current.contentWindow.location.href
			let searchParams = href.split('?');
			if(searchParams[0].includes(RedirectURI)){
				AuthFrame.current.style.display='none'
				let params = new URLSearchParams(searchParams[1])

				let data = {
					code : params.get("code"),
					type : params.get("grant_type"),
					baseUrl : params.get("apiOrigin"),
					client_id : params.get("client_id")
				}
				axios.post(`${data.baseUrl}scanners/auth/authorize`,data).then(({data})=>{
					LOGIN.onLogin(data)
				}).catch(error=>{
					TOAST.error(error.response.data.message)
					AuthFrame.current.contentWindow.location.reload()
				})
			}
		}catch (e) {
			console.log(e)
		}
	}

	return (
		<iframe  title='Dice_Auth' onLoad={startAuth} style={{width:'100%', height: '100%'}} ref={AuthFrame} src={`https://oauth.dice.tech/scanner?origin=${window.location.hostname}`}/>
	);
}
