import React from 'react';
import moment from 'moment';
import './File.css'
import { Attachment } from '../../../../retro';

function FileCard({createdAt, file, name, submitted, id, onClick}) {
    return (
        <>
        <div onClick={onClick} className='FadeInUp-Effect file-card'>
            <div className='mr1' style={{marginLeft: '-0.25rem'}}>
                <Attachment url={file}/>
            </div>
            <div style={{overflow:'hidden'}}>
                <div className='text-truncate'>{name}</div>
                <div className='text-truncate'>{moment(createdAt).format('DD MMM YY')}</div>
            </div>
        </div>
        </>
    );
}

export default FileCard;