import React, { useEffect, useState } from 'react';
import { Loader } from '../../../../../retro';
import { post } from '../../../../Network/Axios';
import Moment from 'moment';

function PODetails({po}) {

    const [Details, setDetails] = useState();
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        fetchPO(po.id)
    }, [po]);

    const fetchPO = (id) => {
        post(`/workflow/po/${id}/details`,{},(e,r)=>{
            if(r){
                setDetails(r);
                setLoading(false);
            }
        })
    }

    if(loading) {
        return <Loader  />
    }

    return (
        <>
          <div style={{
             border:'1px solid var(--theme-border-color)',
             padding:'0.8rem',
             borderRadius:6
          }}>
                <div className='fw-bold mb1 text-small'>PO DETAILS</div>
                <div className='mb1 text-small'><span className='mr1 text-muted'>Purchase ID:</span>{Details.orderId}</div>
                <div className='mb1 text-small'><span className='mr1 text-muted'>Gross Amount :</span> {Details.currency} {Details.grossAmount}</div>
                <div className='mb1 text-small'><span className='mr1 text-muted'>Created  At:</span>{Moment(Details.details.createdAt).format('DD-MM-YYYY')}</div>
                <div className='mb1 text-small'><span className='mr1 text-muted'>Category :</span>{Details.category.name}</div>
          </div>
        </>
    );
}

export default PODetails;