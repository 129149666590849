import React, {useEffect, useState} from 'react'
import {Headings, Grids, Navigation} from "../../../../retro";
import useConfigHook from '../../../Hooks/useConfigHook';
import Insights from './Insights';
import Updates from './Updates';
import {get} from '../../../Network/Axios'

function Dashboard() {

	const CONFIG = useConfigHook();
	const [Invoices, setInvoices] = useState([]);
	const [Approvals, setApprovals] = useState([]);

	useEffect(() => {
		get(`/workflow/pending`, (e,r)=>{
            if(r){
                setApprovals(r.approvals)
            }
        },{offset: 0})
		
        get(`/invoices/pending`, (e,r)=>{
            if(r){
                setInvoices(r.invoices)
            }
        },{offset: 0})
	}, []);

	return (
		<>
		<Navigation title={`Welcome, ${CONFIG.name?CONFIG.name:''}`} chips={['Dashboard']} />
		<div>
			<Grids.Row>
				<Grids.Column grid={{mobile:12, medium: 12, large: 9}} className='mt3 mt-md-5'>
					<Headings.Regular>Insights</Headings.Regular>
					<Insights invoices={Invoices.length} approvals={Approvals.length}/>

					<Headings.Regular className='mt3'>Updates</Headings.Regular>
					<Updates invoice={Invoices[0]} approval={Approvals[0]}/>

				</Grids.Column>
				<Grids.Column grid={{mobile:12, medium: 4, large: 4}}  className='mt3 mt-md-5'>

				</Grids.Column>
			</Grids.Row>
		</div>
		</>
	);
}

export default Dashboard;