//Shortcuts Options
const Shortcuts = {
    title: 'Shortcuts',
    icon: <i className="fas fa-share"></i>,
    list: [
        {title: 'Change Password', url: '/settings'},
    ],
    searchFunction: (item, val)=>{
        if(item.title.toLowerCase().includes(val)){
            return item
        }
    }
}

export default Shortcuts;