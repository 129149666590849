//Shortcuts Options
const Support = {
    title: 'Support',
    icon: <i className="fas fa-headphones-alt"></i>,
    list: [
        {title: 'Contact Support', url: '/support'},
    ],
    searchFunction: (item, val)=>{
        return item
    }
}

export default Support;