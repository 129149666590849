import React from 'react'
import {get} from "../Network/Axios";
import {calculateColors} from "../../retro/Theme/Theme";

//TO BE DONE - THEME CALLING API INTEGRATION
const setTheme=()=>{
			const THEME = calculateColors("#6367FA");
			let element = document.getElementsByTagName("body")[0]
			element.style.setProperty("--ds-background-selected",THEME.primary)
			element.style.setProperty("--ds-surface",THEME.bg)
			element.style.setProperty("--ds-background",THEME.hoverColor)
			element.style.setProperty("--ds-text-selected",THEME.onPrimary)
			element.style.setProperty("--ds-text",THEME.onSurface)
			element.style.setProperty("--theme-bg-container",THEME.bg)
			element.style.setProperty("--theme-surface-color",THEME.bg)
			element.style.setProperty("--theme-variant-color",THEME.surfaceVariant)
			element.style.setProperty("--theme-on-variant-color",THEME.onSurfaceVariant)
			element.style.setProperty("--theme-outline-color",THEME.outline)
			element.style.setProperty("--theme-primary-color",THEME.primary)
			element.style.setProperty("--theme-on-primary-color",THEME.onPrimary)
			element.style.setProperty("--ds-background-success-bold",THEME.primary)
			element.style.setProperty("--theme-primary-container",THEME.primaryContainer)
			element.style.setProperty("--theme-on-primary-container",THEME.onPrimaryContainer)
			element.style.setProperty("--theme-secondary-color",THEME.secondary)
			element.style.setProperty("--theme-on-secondary-color",THEME.onSecondary)
			element.style.setProperty("--theme-secondary-container",THEME.secondaryContainer)
			element.style.setProperty("--theme-on-secondary-container",THEME.onSecondaryContainer)
			element.style.setProperty("--theme-link-color",THEME.link)
			element.style.setProperty("--theme-link-container",THEME.linkContainer)
			element.style.setProperty("--theme-on-link-container",THEME.onLinkContainer)
			element.style.setProperty("--theme-error-color",THEME.error)
			element.style.setProperty("--ds-background-danger",THEME.errorContainer)
			element.style.setProperty("--ds-icon-danger",THEME.error)
			element.style.setProperty("--ds-link",THEME.link)
			element.style.setProperty("--theme-error-container",THEME.errorContainer)
			element.style.setProperty("--theme-on-error-container",THEME.onErrorContainer)
			element.style.setProperty("--theme-hover-color",THEME.linkContainer)
			element.style.setProperty("--ds-background-neutral-bold",THEME.light)
			element.style.setProperty("--theme-muted-color",THEME.light)
			element.style.setProperty("--theme-text-color",THEME.text)
			element = document.getElementsByTagName("body")[0]
			element.style.setProperty("--font-family",`Dice,'proxima-nova',"Dice","Segoe UI",'Roboto', sans-serif`)
			let htmls = document.getElementsByTagName("html")
			if(htmls &&  htmls.length){
				htmls[0].style.fontSize = `16px`;
			}
}

export {
	setTheme
}
