import React from 'react';
import { KeyValue } from '../../../../../retro';

function Inputs({inputs}) {
    return (
        <>
        <div className='fw-bold mb3 text-small'>INPUTS</div>
       {
            inputs&&inputs.length>0 ? 
            <div className='text-small'>
            {
                inputs.map((i, index) => {
                    return (
                        <KeyValue key={index} title={i.key} value={i.value} />
                    )
                })
            }
            </div>
        : null
       }
        </>
    );
}

export default Inputs;