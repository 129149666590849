import React from 'react';
import moment from 'moment';
import {Timeline} from '../../../../../retro'

function Track({timeline}) {
    return (
        <>
        <div className='fw-bold mb3 text-small'>TIMELINE</div>
        <Timeline stages={[...timeline.map((log,index) => {

                let DESC = <div><b>{log.name}</b><br/>
                    <div style={{marginTop: '5px'}}>Type: {log.type}</div>
                    <div style={{marginTop: '5px'}}>Sent
                        on: {moment(log.time).format("hh:mm a DD MMM YY")}
                        <br/>{log.actionTime ? `Approved on: ${moment(log.actionTime).format(" hh:mm a DD MMM YY")}` : ''}
                    </div>
                </div>

                return (
                    {
                        title: log.email,
                        desc: DESC,
                        status: (log.type === 'scanner-start'&&timeline.length>index+1) ? true: log.type === 'start' || log.type === 'scanner-end' || log.email ==='Finance Action' ? true : log.status ? log.status === 'COMPLETED' : false
                    })
            })]
        }/>
        </>
    );
}

export default Track;