import * as Crypto from "../Utils/Crypto";
import axios from 'axios';
import {getBaseUrl, Wrapper as DecentralizeWrapper, getToken} from './DecentralizeWrapper'

let isRefreshing = false;

axios.defaults.timeout = 62500 * 3;
axios.interceptors.response.use(response => {
	return response;
}, function (error) {
	const err = error.response;
	if(error.response.config.url==='auth/token/refresh'){
		localStorage.clear()
		window.location.reload();
	}
	if (err && err.status === 401 && err.config) {
		const originalRequest = err.config;
		if (!isRefreshing) {
			isRefreshing = true;
			getRefreshToken()
				.then(function (success) {
					onRefreshed(success.data.access_token)
				}).catch(err => {
					localStorage.clear()
					window.location.reload();
				});
		}
		return new Promise((resolve, reject) => {
			subscribeTokenRefresh(token => {
				originalRequest.headers['Authorization'] = 'Bearer ' + token;
				resolve(axios(originalRequest));
			});
		});
	} else {
		if (err)
			return Promise.reject(err);
	}
});

axios.interceptors.request.use(async function (config) {
	const token = Crypto.get('token');
	if (token !== null && token !== "") {
		config.headers.Authorization = `Bearer ${token}`;

	}
	return config;
}, function (err) {
	return Promise.reject(err);
});

function getRefreshToken() {
	let refreshtoken = Crypto.get("refresh_token");
	if (!refreshtoken) {
		localStorage.clear()
		window.location.reload();
	}
	return axios.post(`${getBaseUrl()}/auth/token/refresh`, {
		token: refreshtoken
	});
}

let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
	refreshSubscribers.push(cb);
}

const onRefreshed = (token) => {
	setTokens(token);
	isRefreshing = false;
	refreshSubscribers = refreshSubscribers.filter(cb => {
		cb(token);
		return false;
	});
	refreshSubscribers = [];
}

const setTokens = (token) => {
	Crypto.set('token', token);
}


const MainApis = new DecentralizeWrapper()
const get = MainApis.get;
const patch = MainApis.patch;
const post = MainApis.post;


export default axios;
export {
	axios,
	get,
	post,
	getToken,
	patch,
}
