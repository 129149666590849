import {useSelector,useDispatch} from "react-redux";
import {useEffect} from "react";
import {get} from "../Network/Axios";
import {AuthActions} from "../Redux/Reducers/auth";

export default function useConfigHook(){
	const dispatch = useDispatch();
	const {config, auth} = useSelector(state => ({config:state.auth.config, auth: state.auth}));
	useEffect(()=>{
		if(config.loading && auth.authenticated){
			get('/config/config',(e,r)=>{
				if(r){
					dispatch({
						type: AuthActions.Config,
						data:r
					})
				}
			})
		}
	},[config, auth, dispatch])
	return {
		...config
	}
}
