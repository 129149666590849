import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import quotedPrintable from 'quoted-printable'
import utf8 from 'utf8'
import moment from 'moment';
import { Button, Attachment, Loaders } from '../../../../../retro';
import {TOAST, PageLoad} from '../../../../../retro';
import {get} from '../../../../Network/Axios'

function Index({mailID, onClose}) {

    const [Loading, setLoading] = useState(true);
    const [Content, setContent] = useState({from: '',subject: '',createdAt: '',body: '',invoice: '',submitted: '',attachments: []});

    useEffect(() => {
        openMail(mailID)
    }, [mailID]);

    const openMail = (id)=>{
        get(`inbox/${id}/details`, (e,r)=>{
            if(r){
                document.getElementById('Mail-Content').innerHTML = utf8.decode(quotedPrintable.decode(r.body));
                setContent({...r,
                body: utf8.decode(quotedPrintable.decode(r.body))
                });
                setLoading(false)
                PageLoad(`Mail Open ${id}`)
            }else if(e){
                TOAST.error('Problem in opening Mail')
            }
        })
    }

    let HISTORY = useHistory();

    return (
            <div className='FadeIn-Effect selectable'>
                <div className='w-100 flex justify-between pb2 display-md-none pointer bg-white pt3' style={{fontSize:'1.2rem', position:'sticky',top:0, zIndex:5}}>
                    <div className='text-truncate fw-bold pr3'>
                        {Content.subject}
                    </div>
                    <i onClick={onClose} className="fas fa-times-circle" style={{fontSize:'1.7rem'}}/>
                </div>
                {
                    !(Content.submitted && Content.attachments.length===0)&&
                    <div className='submit_message mt4'>
                        This mail includes an invoice attachment.<br/> Do you want to submit this?
                        <Button onClick={()=>HISTORY.push({pathname: '/submit', Content, mailID})} className='btn-black' margin='0'>
                            Submit
                        </Button>
                    </div>
                }

                <div className='mt4 break-word'>
                    {Content.subject}
                </div>
                <div className='flex align-center justify-between text-small mt3'>
                    <div style={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                        <div className='flex align-center break-word' id="Mail-From">
                          From: {Content.from}
                        </div>
                    </div>
                    <div id="Mail-Date">{moment(Content.createdAt).format(" hh:mm a DD MMM YY")}</div>
                </div>

                <div id="Attachments">
                    {Content.attachments.length>0&&
                    <>
                        <div className='text-small fw-bold mt3'>Attachments</div>
                        <div className='flex flex-wrap mt2'>
                            {Content.attachments.map((link,index)=>{
                                return <Attachment key={index} url={link}/>
                            })}
                        </div>
                    </>
                }
                </div>
                <div className='mt3 text-small w-100 break-word' id="Mail-Content" style={{overflowX:'scroll'}}>
                    <div className='w-100 text-center'>
                        {Loading&&<Loaders.Medium/>}
                    </div>
                </div>
            </div>
    );
}

export default Index;