//SiteMap
const SiteMap = {
    title: 'Pages',
    icon: <i className="fas fa-link"></i>,
    list: [
        {title: 'Inbox', url: '/inbox'},
        {title: 'Invoices', url: '/invoices'},
        {title: 'Past Invoices', url: '/invoices/completed'},
        {title: 'Uploaded Files', url: '/uploads'},
        {title: 'Pending Approvals', url: '/approvals'},
        {title: 'Submit Invoice', url: '/submit'},
    ],
    searchFunction: (item, val)=>{
        if(item.title.toLowerCase().includes(val)){
            return item
        }
    }
}

export default SiteMap;