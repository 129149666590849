import React from 'react';
import { Comments, TOAST } from '../../../../../retro';
import {post} from '../../../../Network/Axios'

function Inputs({comments, id, onReload}) {

    

    const addComment = (comment) => {
        post(`workflow/workflow/${id}/comment`,{comment},(e,r)=>{
			if(r){
                onReload()
			}else if(e){
				TOAST.handleError(e)
			}
		})
    }

    return (
        <>
        <Comments comments={comments} addComment={addComment}/>
        </>
    );
}

export default Inputs;