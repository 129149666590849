import React from 'react'
import './InlineTabs.css'
import {useHistory, useLocation} from "react-router-dom";

export default function InlineTabs({ path = false,tabs = [], className, style }) {
	const LOCATION = useLocation();
	const HISTORY = useHistory();
	if(path){
		return (
			<div className={`inline-tabs ${className}`} style={style}>
				{
					tabs.map((item,index)=>{
						return (
							<div className={`tab ${item.path===LOCATION.pathname?'active':''}`} key={index} onClick={()=>{
								HISTORY.push(item.path)
							}} >
								{item.title}
							</div>
						)
					})
				}
			</div>
		)
	}
	return (
		<div className={`inline-tabs ${className}`} style={style}>
            {
                tabs.map((item,index)=>{
                    return (
                        <div className={`tab ${item.active?'active':''}`} key={index} onClick={item.onClick}>
                            {item.title}
                        </div>
                    )
                })
            }
		</div>
	)
}
