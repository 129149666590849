import React from 'react';
import {Attachment, FileInput, TOAST} from '../../../../../retro';
import {post} from '../../../../Network/Axios'
import UpdateInvoiceIndia from '../../../../Components/Invoice/India/Update'
import UpdateInvoiceOther from '../../../../Components/Invoice/Other/Update'

function Format({details, onReload, id, disabled}) {

    
    let {invoice={}, category, taxInformation={}, service, renderer} = details;

    const uploadFile = (file) => {
        let data = {
            name: "",
            file: file,
            type: ""
        };
		post(`/invoices/${id}/attachment`, data, (e, r) => {
			if (r) {
				onReload()
				TOAST.success('File Uploaded')
			} else if (e) {
				TOAST.handleError(e);
			}
		});
	}

    return (
        <>
        {
            details.po&&
            <div className='bg-light pd1 rounded-md text-small mb2'>
                <i className='mr1 fas fa-info-circle'/>Submitted against PO <b>{details.po.poId}</b>
            </div>
        }
        <div>
            <div className='fw-bold mb1 text-small'>DETAILS</div>
            <div className='mb1 text-small'><span className='mr1 text-muted'>Invoice ID:</span>{invoice.invoiceId}</div>
            <div className='mb1 text-small'><span className='mr1 text-muted'>PO ID:</span>{details.po?details.po.poId:'-'}</div>
            <div className='mb1 text-small'><span className='mr1 text-muted'>Category:</span>{category}</div>
            <div className='mb1 text-small'><span className='mr1 text-muted'>Amount:</span>{`INR ${taxInformation.paidAmount}`}</div>
            <div className='mb1 text-small'><span className='mr1 text-muted'>Service:</span>{service}</div>
        </div>

        <div className='mt4'>
            <div className='fw-bold mb1 text-small'>UPDATE INVOICE</div>
            {
                renderer==='INDIA'
                ?<UpdateInvoiceIndia disabled={disabled} onReload={onReload} id={id} {...details}/>
                :<UpdateInvoiceOther disabled={disabled} onReload={onReload} id={id} {...details}/>
            }
        </div>

        <div className='mt4'>
            <div className='fw-bold mb1 text-small'>ATTACHMENTS</div>
            <div className='flex align-center flex-wrap' style={{marginLeft:'-0.25rem'}}>
                {
                    !disabled&&<FileInput updateFiles={uploadFile}/>
                }
                {
                    invoice.files&&invoice.files.map((file, index)=>{
                        return (
                            <Attachment key={index} url={file}/>
                        )
                    })
                }
            </div>
        </div>
        </>
    );
}

export default Format;