import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import * as ServiceWorker from './serviceWorker';
import Moment from 'moment';
let proto = Moment.prototype;
proto.getTime = proto.valueOf;
Moment.prototype = proto;

const root = ReactDOM.createRoot(
	document.getElementById('root')
);
root.render( <App />);
ServiceWorker.register({
	onUpdate: reg => {
		const waitingServiceWorker = reg.waiting
		waitingServiceWorker.addEventListener("statechange", event => {
			if (event.target.state === "activated") {
				console.log("Updated")
			}
		});
		waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
	}
})

