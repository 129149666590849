import React from 'react';
import { Tabs, Navigation } from '../../../../retro';
import { Switch, Route } from 'react-router-dom';
import Pending from './Pending'
import Submitted from './Submitted'

function Invoices() {

    return (
        <>
        <Navigation title='Uploaded Files' backUrl='/' chips={['Scans']} />
        <Tabs background='var(--theme-bg-container)' top='3.87rem' tabs={[{path: '/uploads', title: <><i className="fas fa-inbox mr1"></i>Pending</>},{path: '/uploads/submitted', title: <><i className="far fa-folder-open mr1"></i>Submitted</>}]}/>
        <Switch>
            <Route path="/uploads" exact component={Pending}/>
            <Route path="/uploads/submitted" exact component={Submitted}/>
        </Switch>
        </>
    );
}

export default Invoices;