import React, {useEffect} from 'react'
import {Sidebar, Wrapper,  NotFound, Spotlight, AnalyticsContainer, FloatingUrl} from "../../../retro";
import { Switch,  Route, useHistory, useLocation} from "react-router-dom";
import useConfigHook from "../../Hooks/useConfigHook";
import AppIcon from '../../Assets/Images/full_logo.png'
import useSidebarConfig from "../../Hooks/Sidebar/useSidebarConfig";
import Logout_Icon from '../../Assets/SVG/logout.svg'
import Settings_Icon from '../../Assets/SVG/settings.svg'
import Notification from '../../Assets/SVG/help.svg'
import './index.css'
import Inbox from './Inbox'
import Invoices from './Invoices'
import SubmitInvoice from './SubmitInvoice'
import Settings from './Settings'
import Uploads from './Uploads'
import Approvals from './Approvals'
import Dashboard from './Dashboard'
import mixpanel from 'mixpanel-browser'
import Support from './Support'
import SiteMap from './Spotlight/Sitemap'
import Shortcuts from './Spotlight/Shortcuts'
import SupportOptions from './Spotlight/Support'
import { setTheme } from '../../Hooks/useThemeHook';

const OurAnalyticsListener = ({ children }) => {
	const CONFIG = useConfigHook();
	
	useEffect(()=>{
		if(!CONFIG.loading){
			mixpanel.init('84b896917b0f9679a3011671a8f82d5e');
			mixpanel.identify(CONFIG.email);
			mixpanel.set_group("tenant",[CONFIG.company])
			mixpanel.people.set({ "$name": CONFIG.name, "name": CONFIG.name, "$email": CONFIG.email,"email": CONFIG.email,"config": CONFIG  });
		}
	},[CONFIG])

	useEffect(()=>{
		setTheme()
	},[])

	const onDiceEvent = (channel,event) => {
		mixpanel.track(event.payload.type,{
			...event.payload,
			time:event.time
		})
	};
	return (
		<AnalyticsContainer channel="dice" onEvent={onDiceEvent}>
			{children}
		</AnalyticsContainer>
	)
}

export default function Render() {

	let LOCATION = useLocation()
	let pathname = LOCATION.pathname;
	const CONFIG = useConfigHook();
	const ITEMS = useSidebarConfig();
	const HISTORY = useHistory()
	
	return (
			<OurAnalyticsListener key="analytics">
				<FloatingUrl/>
				<Spotlight config={[SiteMap, Shortcuts, SupportOptions]}/>
				<Sidebar data={{
					name: `${CONFIG.name} - ${CONFIG.company}`,
					email: CONFIG.email,
					children: (
						<div id="sidebar-top" className="flex align-center mt2">
							<div onClick={()=>HISTORY.push('/settings')} className="icon mr1">
								<svg width="1.4rem" height="1.4rem"><image xlinkHref={Settings_Icon} width="1.4rem" height="1.4rem"/></svg>
							</div>
							<div onClick={()=>HISTORY.push(`${pathname}?search=`)} className="icon mr1">
								<svg width="1.4rem" height="1.4rem"><image xlinkHref={Notification} width="1.4rem" height="1.4rem"/></svg>
							</div>
							<div onClick={()=>{window.localStorage.clear();window.location.reload()}} className="icon">
								<svg width="1.4rem" height="1.4rem"><image xlinkHref={Logout_Icon} width="1.4rem" height="1.4rem"/></svg>
							</div>
						</div>
					)
				}} items={ITEMS} logo={AppIcon}/>
				<Wrapper>
					<Switch>
						<Route path="/support" component={Support}/>
						<Route path="/approvals" component={Approvals}/>
						<Route path="/uploads" component={Uploads}/>
						<Route path="/settings" component={Settings}/>
						<Route path="/submit" component={SubmitInvoice}/>
						<Route path="/invoices" component={Invoices}/>
						<Route path="/inbox" exact component={Inbox}/>
						<Route path="/" exact component={Dashboard}/>
						<NotFound/>
					</Switch>
				</Wrapper>
			</OurAnalyticsListener>
	)
}
