import React, { useEffect, useRef, useState } from 'react';
import { get, post } from '../../../Network/Axios';
import AddCircleIcon from '@atlaskit/icon/glyph/add-circle';
import { CustomForm, Modal, TOAST } from '../../../../retro';

const Item = ({item,categoryId,invoiceId,refresh}) => {

    const [form,setForm] = useState([]);
    const [open,setOpen] = useState(false);
    const CUSTOM = useRef();

    useEffect(()=>{
        get(`/formSearch/invoice::item::${categoryId}/form`,(e,r)=>{
            if(r) {
                setForm(r.forms);
            }
        })
    },[]);

    let add = () => {
        if(CUSTOM.current()) {
            post(`/invoices/${invoiceId}/itemForm`,{name:item.name,units:item.unit,service:item.service,hsn:item.hsn,forms:CUSTOM.current()},(e,r)=>{
                if(r) {
                    TOAST.success("Form Update successfully");
                    setOpen(false);
                    refresh();
                } else {
                    TOAST.error(e.data.message);
                }
            })
        }
    }

    return(
        <>
          <tr>
            <td>
                {item.name}{form.length>0?<span style={{marginLeft:5}} onClick={()=>{
                    setOpen(form);
                }}><AddCircleIcon  size="small"/></span>:null} <br></br>
                <span style={{fontSize:11}}>{item.service}</span>
              </td>
            <td>{parseInt(item.amount) - parseInt(item.tax)}</td>
            <td>{item.qtty}</td>
            <td>{item.tax?item.tax.toFixed(2):0}</td>
            <td>{item.amount?item.amount.toFixed(2):item.amount} </td>
        </tr>
        {
            open ?
            <Modal title='Add Form' description='Add Form in Item' onSubmit={add} onClose={()=>setOpen(false)} button='Add'>
                <CustomForm forms={form} handleRef={(cb)=>{
                    CUSTOM.current=cb
                }}  />
            </Modal>
        :null
        }
        </>
       
    )
}

export default Item;