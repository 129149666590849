import {Icons} from "../../../retro";

export default function useSidebarConfig(){

	const ITEMS = [];
	ITEMS.push({
		title:"Dashboard",
		items:[
			{
				name:"Dashboard",
				path:"/",
				type:"equals",
				icon: <Icons.Dashboard size="small"/>
			},
			{
				name:"Inbox",
				path:"/inbox",
				icon:<Icons.Structure size="small"/>
			},
			// {
			// 	name:"Uploaded Files",
			// 	path:"/uploads",
			// 	icon:<Icons.Folder size='small'/>
			// },
			{
				name:"Approvals",
				path:"/approvals",
				icon:<Icons.Approved size='small'/>
			}
		]
	})
	ITEMS.push({
		title:"Invoices",
		items:[
			{
				name:"Invoices",
				path:"/invoices",
				type:"/invoices",
				icon:<Icons.Expense size='small'/>
			},
			{
				name:"Submit Invoice",
				path:"/submit",
				icon:<Icons.Project size='small'/>
			}		]
	})
	return ITEMS;
}
