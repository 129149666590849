import React, {useEffect, useState} from 'react';
import useConfigHook from '../../../Hooks/useConfigHook';
import {PageLoad, TOAST, UserAction, ChatBox} from '../../../../retro'
import {get, post} from '../../../Network/Axios'

function Chat({onClose, id}) {

    const [Details, setDetails] = useState(undefined);
    const CONFIG  = useConfigHook()

    useEffect(() => {
        fetchTicket(id)
        PageLoad(`Ticket Open ${id}`)
        let interval = setInterval(() => {
            fetchTicket(id)
        }, 5000);

        return ()=>{
            clearInterval(interval)
        }
    }, [id]);

    const fetchTicket = (ID) => {
        get(`support/${ID}`, (e,r)=>{
            if(r){
                setDetails(r)
            }else if(e){
                TOAST.error('Problem in opening Mail')
            }
        })
    }

    const sendMessage = (type, content, ID) => {
        if(!content || !type || !ID){
            return
        }

        post(`support/${ID}`, {content, type}, (e,r)=>{
            if(r){
                fetchTicket(ID)
                UserAction('Support Message Sent', ID)
            }else if(e){
                TOAST.error('Problem in sending message')
            }
        })
    }

    return (
        <>
        <ChatBox config={CONFIG} id={id} onClose={onClose} title={Details&&Details.title} category={Details&&Details.category} comments={Details?Details.comments:[]} onSend={sendMessage}/>
        </>
    );
}

export default Chat;