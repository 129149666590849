import React, {useEffect} from 'react'
import {useHistory} from "react-router-dom";
import {useLoginHook} from "../../Hooks/useLoginHook";
import {Headings, Loaders, Texts} from "../../../retro";
import Back from '../../Assets/Images/back.jpg'
import Logo from '../../Assets/Images/full_logo_white.png'
import Login from './Login'

export default function Render() {
	
	const HISTORY = useHistory();
	const LOGIN = useLoginHook();
	useEffect(()=>{
		if(LOGIN.authenticated){
			HISTORY.push("/")
		}
	},[LOGIN.authenticated, HISTORY])

	return (

		<div className='h-100h w-100w'>
			<div className="overScroll h-100 w-100">
				<div className="container h-100 w-100" style={{maxWidth: '100%'}}>
					<div className='row h-100'>
						<div className="col-12 col-md-7 display-none display-md h-100 pd4" style={{background: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.7)), url(${Back})`, backgroundPosition: 'left', backgroundSize:'cover', backgroundRepeat: 'no-repeat',}}>
							<div className='h-100 flex column justify-between text-white'>
								<img src={Logo} alt='Logo' style={{maxWidth:'100px'}}/>
								<div className='flex justify-between' style={{alignItems: 'end'}}>
									<div>
										<Headings.Medium className='text-white'>
											Scanner Room
										</Headings.Medium>
										<a href='https://www.dice.tech' className='text-white'>
											<Texts.Regular className='mt3 pointer'>
												© Dice 2022, All Rights Reserved.
											</Texts.Regular>
										</a>
									</div>
									<a href='https://www.dice.tech'  className='text-white'>
										<Texts.Regular className='pointer'>
											Learn more about Dice <i className="ml1 fa fa-long-arrow-right"/>
										</Texts.Regular>
									</a>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-5 h-100" style={{background: '#fff'}}>
							<div className='h-100 flex column justify-between'>
								<div className="pd2 relative m-auto w-100 h-100" style={{maxWidth:400}}>
									<div className='relative h-100 flex center' style={{zIndex:2}}>
										<Login/>
									</div>
									<div className='absolute' style={{top:'50%', left:'50%', transform: 'translate(-50%, -50%)', zIndex:1}}>
										<Loaders.Medium/>
									</div>
								</div>
								<div className='w-100 pd3 display-md-none' style={{background: 'var(--theme-primary-container)', bottom: 0, left:0}}>
									<Headings.Medium>
										Scanner Room
									</Headings.Medium>
									<a href='https://www.dice.tech'>
										<Texts.Regular className='pointer'>
											Learn more about Dice <i className="fa fa-long-arrow-right"/>
										</Texts.Regular>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
