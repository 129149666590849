import {useSelector,useDispatch} from "react-redux";
import * as Crypto from "../Utils/Crypto";
import Moment from 'moment'
import {AuthActions} from "../Redux/Reducers/auth";

function useLoginHook() {
	const  dispatch = useDispatch();
	let {auth} = useSelector((state => ({auth: state.auth})));
	return {
		...auth,
		onLogin:data=>{
			Crypto.set('token', data.access_token);
			Crypto.set('refresh_token', data.refresh_token);
			Crypto.set("dice-scan-last", Moment().valueOf())
			Crypto.set("dice-scan-name",data.name)
			Crypto.set("dice-scan-login-in", "yes");
			dispatch({
				type: AuthActions.Login,
				data: {
					name: data.name,
					login: Moment()
				}
			})
		},
		reloadConfig:()=>{

		}
	}
}


export {
	useLoginHook
}
