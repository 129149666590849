import React from 'react';
import './Invoice.css'
import { Headings, Loaders } from '../../../../retro';

function Preview({address, category, chips, content, deliverTo, dueOn, duplicates, invoice, officeName, owner, renderer, tags, taxInformation, timelines, type, vendor, view, style}) {

    if(!invoice){
        return <div className='w-100 text-center'><Loaders.Medium/></div>
    }

    return(
            <div className='FadeInUp-Effect text-small w-100 h-100 overScroll pd4' style={style}>
                <Headings.Regular className='text-center'>INVOICE</Headings.Regular>
                <div className='border pd2 rounded-sm mt2'>
                    <div className='flex justify-between'>
                        <div>
                            <b>Vendor</b><br/>
                            {vendor.name}<br/>
                            Email: {vendor.email}<br/>
                            GSTIN: {vendor.gstin}
                        </div>
                        <div style={{textAlign: 'right'}}>
                            Date: {invoice.date}<br/>
                            Invoice ID: {invoice.invoiceId}<br/>
                        </div>
                    </div>
                    <div className='flex justify-between mt2'>
                        <div>
                            <b>Billing Address</b><br/>
                            {address.address}
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <b>Shipping Address</b><br/>
                            {deliverTo.address}
                        </div>
                    </div>
                </div>
                
                <table>
                    <thead><tr><th>Description</th><th>Base Amt</th><th>Tax Amt</th><th>Amount (INR)</th></tr></thead>
                    <tbody>
                    <tr><td>Description</td><td>Base Amt</td><td>Tax Amt</td><td>Amount (INR)</td></tr>
                        {
                            invoice.items.map(((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td>{parseInt(item.amount) - parseInt(item.tax)}</td>
                                        <td>{item.tax}</td>
                                        <td>{item.amount}</td>
                                    </tr>
                                )
                            }))
                        }
                    </tbody>
                </table>

                <div className='invoice-taxes'>
                    <div className='wrap'>
                        <div>CGST: </div>
                        <div> {taxInformation.cgst}</div>
                    </div>
                    <div className='wrap'>
                        <div>SGST / UTGST: </div>
                        <div> {parseInt(taxInformation.sgst) + parseInt(taxInformation.utgst)}</div>
                    </div>
                    <div className='wrap'>
                        <div>IGST: </div>
                        <div> {taxInformation.igst}</div>
                    </div>
                    <div className='wrap'>
                        <div>Tax Amt: </div>
                        <div> {taxInformation.gst}</div>
                    </div>
                    <div className='wrap'>
                        <div>Base Amt: </div>
                        <div> {taxInformation.baseAmount}</div>
                    </div>
                    <div className='wrap'>
                        <div>Total Amt: </div>
                        <div> {parseInt(taxInformation.baseAmount) + parseInt(taxInformation.tax)}</div>
                    </div>
                    <div className='wrap'>
                        <div>TDS Amt: </div>
                        <div> {taxInformation.tds}</div>
                    </div>
                </div>

                <div className='flex fw-bold' style={{padding: '0.5rem 0px', borderTop: '1px solid #000', borderBottom: '1px solid #000'}}>
                    <div style={{flexGrow: 1}}>Total Payable Amount: </div>
                    <div style={{width: '100px', textAlign: 'right'}}> {taxInformation.paidAmount}</div>
                </div>
            </div>
    )
}

export default Preview;