import React from 'react';
import { Tabs, Navigation } from '../../../../retro';
import { Switch, Route } from 'react-router-dom';
import Pending from './Pending'
import Completed from './Completed'

function Invoices() {

    return (
        <>
        <Navigation title='Invoices' backUrl='/' chips={['Invoices']} />
        <Tabs background='var(--theme-bg-container)' top='3.87rem' tabs={[{path: '/invoices', title: <><i className="far fa-hourglass-half mr1"></i>Pending</>},{path: '/invoices/completed', title: <><i className="fas fa-history mr1"></i>Completed</>}]}/>
        <Switch>
            <Route path="/invoices" exact component={Pending}/>
            <Route path="/invoices/completed" exact component={Completed}/>
        </Switch>
        </>
    );
}

export default Invoices;