import React from 'react';
import {KeyValue, Seperator} from '../../../../../retro'

function Details({task={}, onReload, id, disabled}) {

    let {views=[], details} = task;
    let DATA  = JSON.parse(details.data)

    const renderKeyValue = (item, obj, index) => {
        if(typeof(obj[item])==='object'){
            return Object.keys(obj[item]).map((node, i)=>renderKeyValue(node, obj[item], i))
        }else if(typeof(obj[item])==='string'){
            return (
                <KeyValue key={index} title={item} value={obj[item].toString()} />
            )
        }
        return <div key={index}/>
    }

    return (
        <>
        <div className='text-small'>
            <div className='fw-bold mb1'>DETAILS</div>
            {
                //Render Details
                Object.keys(DATA).map((item, index)=>renderKeyValue(item, DATA, index))
            }
        </div>
        
        <Seperator/>

        <div>
            {//Render Views
                views.map((item, index) => (
                    <div key={index}>
                        <div className='text-small mt4'>
                            <div className='mb2 '>
                                <div className='fw-bold uppercase'>{item.title}</div>
                                <div className='text-muted'>{item.description}</div>
                            </div>
                            {
                                item.values.map((i, ind) => {
                                    return <KeyValue key={ind} title={i.key} value={i.value} />
                                }
                            )}
                        </div>
                        <Seperator/>
                    </div>
                ))
            }
        </div>
        </>
    );
}

export default Details;