import React, {useState, useRef, useEffect} from 'react';
import {Grids, Modal, SearchInput, SearchableSelect, Input, TOAST, Headings, FileInput, Attachment, DateInput, Button, SelectInput, Texts} from '../../../../retro'
import {get} from '../../../Network/Axios'
import moment from 'moment';
import { useLocation } from 'react-router-dom';

function Submit({updatePreview, mailId, onSubmit, update}) {

    let LOCATION = useLocation();

    const [AllCategories, setAllCategories] = useState([]);
    const [AllServices, setAllServices] = useState([]);

    const [Category, setCategory] = useState(undefined);
    const [Service, setService] = useState(undefined);
    const [Vendor, setVendor] = useState(undefined);
    const [LineItems, setLineItems] = useState([]);

    const [BillingOffice, setBillingOffice] = useState(undefined);
    const [ShippingOffice, setShippingOffice] = useState(undefined);

    const [Attachments, setAttachments] = useState([]);
    const [AddModal, setAddModal] = useState(undefined);

    const [DISCOUNT, setDISCOUNT] = useState(0);

    let INVOICE_ID = useRef(), DATE = useRef(), DUE = useRef(), SGST = useRef(), CGST = useRef(), IGST = useRef(), BASE = useRef(), TOTAL = useRef();
    let NAME = useRef(), PRICE = useRef(), QTY = useRef(), TAX = useRef();

    //Pull Prefills if redirected from Inbox
    useEffect(() => {
        let content = LOCATION.content;
        let files = content?LOCATION.content.attachments:[];

        if(files.length){
            setAttachments(files)
        }

    }, [LOCATION]);

    //update prop to update Invoice from outside
    useEffect(() => {
        if(update){
            update(updateDetails)
        }
    }, [update]);

    const updateDetails = (obj) => {
        if(obj.attachments){
            setAttachments(prev=>[...prev, ...obj.attachments])
        }
    }

    //Updating Preview
    useEffect(() => {
        if(updatePreview){
            updatePreview({
                vendor: Vendor,
                billing: BillingOffice,
                shipping: ShippingOffice,
                attachment: Attachments
            })
        }
    }, [Vendor, BillingOffice, ShippingOffice, updatePreview, Attachments]);
    
    useEffect(()=>{
        get(`search/categories`, (e, r) => {
            if (r) {
                setAllCategories(r.items.map(val=>({
                    name:val.name,
                    id:val.id
                })))
            }
        })
    },[])

    useEffect(() => {
        if(Category){
            get(`search/${Category.id}/services`,(e,r)=>{
                if(r){
                    setAllServices(r.items.map(i=>{
                        return {
                            id:i.id,
                            name:i.name
                        }
                    }))
                }else if(e){
                    TOAST.handleError(e)
                }
            })
        }
    }, [Category]);

    //Calculate and apply discount
    useEffect(() => {
        if(LineItems && LineItems.length){
            let updatedItems = LineItems.map(item=>{
                let price = item.unitPrice - (item.unitPrice*DISCOUNT/100);
                let base = price*item.qtty;

                return {
                        ...item,
                        price,
                        base,
                        tax : Number((base*item.gst/100).toFixed(2)),
                        total : Number(((base*item.gst/100) + base).toFixed(2)),
                }
            })
            setLineItems(updatedItems)
        }
    }, [DISCOUNT]);// eslint-disable-line react-hooks/exhaustive-deps

    const removeAttachment = (file) => {
        let files = Attachments.filter((attachment=>attachment!==file))
        setAttachments(files)
    }

    const addLineItem = (params) => {
        let name = NAME.current.value;
        let qtty = Number(QTY.current.value);
        let price = Number(PRICE.current.value);
        let taxPercent = Number(TAX.current.value);

        if(!Service) {
            TOAST.error('Please select a service') 
            return;
        }
        if(!name) {
            TOAST.error('Enter Product name') 
            return;
        }
        if(!qtty) {
            TOAST.error('Enter Product quantity') 
            return;
        }
        if(!price) {
            TOAST.error('Enter Product price') 
            return;
        }
        if(isNaN(taxPercent)) {
            TOAST.error('Select Tax on this product') 
            return;
        }

        let base = price*qtty;

        let item = {
                name,
                qtty,
                unitPrice: price,
                price,
                base,
                gst:taxPercent,
                taxSlab: taxPercent,
                tax : Number((base*taxPercent/100).toFixed(2)),
                total : Number(((base*taxPercent/100) + base).toFixed(2)),
                service: {...Service, label: Service.name, value: Service.id}
        }
        setLineItems(prev=>[...prev, item])
        setService(undefined)
        setAddModal(undefined)

    }

    const removeItem = (val) => {
        let items = LineItems.filter((item)=>item.name!==val)
        setLineItems(items)
    }

    let submitInvoice = () => {

        let vendor = Vendor&&Vendor.addresses[0].address;
        let billNumber = INVOICE_ID.current.value;
        let billDate = DATE.current.value;
        let dueDate = DUE.current.value;

        if(!Category) {
            TOAST.error('Please select Category') 
            return;
        }

        if(!vendor) {
            TOAST.error('Please select the Vendor address') 
            return;
        }

        if(!BillingOffice) {
            TOAST.error('Please select the Billing Office') 
            return;
        }

        if(!ShippingOffice) {
            TOAST.error('Please select the Shipping Office') 
            return;
        }

        if(!billNumber) {
            TOAST.error('Please enter the Bill Number')
            return;
        }

        if(!billDate) {
            TOAST.error('Please select the Bill Date')
            return;
        }

        if(!dueDate) {
            TOAST.error('Please select the Due Date')
            return;
        }

        if(!LineItems.length){
            TOAST.error('Add Line Items')
            return;
        }

        if(billDate) {
            billDate = moment(moment(billDate,'YYYY-MM-DD')).valueOf();
            dueDate = moment(moment(dueDate,'YYYY-MM-DD')).valueOf();
        }

        let data  = {
            addressId: Vendor.registeredId,
            billingTo: {...BillingOffice, value: BillingOffice.id, label: BillingOffice.name},
            shippingTo: {...ShippingOffice, value: ShippingOffice.id, label: ShippingOffice.name},
            vendor: {...Vendor, value: Vendor.id, label: Vendor.title, name: Vendor.title, address: Vendor.addresses?Vendor.addresses[0].address:''},
            billNumber,
            billDate,
            dueDate,
            attachment: Attachments,
            categoryId: Category.id,
            lineItems: LineItems,
            discount: DISCOUNT
        }

        onSubmit(data, 'multiline')
    }

    //Tax Calculations
    let Base = 0, Cgst = 0, Sgst = 0, Igst= 0, Total = 0, Tax = 0;

	LineItems.forEach(item=>{
		Base += item.base;
		Total += item.total;
		Tax += item.tax;
	})

	if( (Vendor&&Vendor.gstin&&Vendor.gstin.length>2) && (BillingOffice&&BillingOffice.gstin&&BillingOffice.gstin.length>2) ){
        //Check for same state GST
		if(Vendor.gstin.slice(0,2)===BillingOffice.gstin.slice(0,2)){
			Cgst = Tax/2;
			Sgst = Tax/2;
		}else{
			Igst = Tax;
		}
	}else{
		Igst = Tax;
	}

    return (
        <>
        <Grids.Container style={{margin:0}}>
            <Headings.Regular>Attachments</Headings.Regular>
            <Texts.Small>Add Invoice copy and other files</Texts.Small>
            <div className='flex align-center flex-wrap mt1' style={{marginLeft:'-0.25rem'}}>
                <FileInput updateFiles={(file)=>{setAttachments(prev=>[...prev, file])}}/>
                {
                    Attachments.map((file, index)=>{
                        return (
                            <Attachment onDelete={removeAttachment} key={index} url={file}/>
                        )
                    })
                }
            </div>

            <div className='mt4 border-top'/>
            
            <Headings.Regular className='mt3'>Vendor</Headings.Regular>
            <Grids.Row>
                <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                    <SearchableSelect className='mr1 mt1' list={AllCategories} onSelect={setCategory} selected={Category} label='Select Category' placeholder='Select Category' value=''/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                    <SearchInput searchKey='title' className='mr1 mt1' api='/search/vendor' onSelect={setVendor} selected={Vendor} label='Select Vendor' placeholder='Search Vendor' value=''/>
                </Grids.Column>
            </Grids.Row>

            <div className='mt4 border-top'/>
            
            <Headings.Regular className='mt3'>Billing to</Headings.Regular>
            <Grids.Row>
                <Grids.Column grid={{mobile: 6, medium: 6, large: 6}}>
                    <Input ref={INVOICE_ID} className='mr1 mt1' label='Bill Number' placeholder='Bill Number' type='text'/>
                </Grids.Column>
            </Grids.Row>
            <Grids.Row>
                <Grids.Column grid={{mobile: 6, medium: 6, large: 6}}>
                    <DateInput ref={DATE} className='mr1 mt1' label='Invoice Date'/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 6, medium: 6, large: 6}}>
                    <DateInput ref={DUE} className='mr1 mt1' label='Due Date'/>
                </Grids.Column>
            </Grids.Row>
            <Grids.Row>
                <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                    <SearchInput className='mr1 mt1' api='/search/offices' onSelect={setBillingOffice} selected={BillingOffice} label='Billing Address' placeholder='Search Office' value=''/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                    <SearchInput className='mr1 mt1' api='/search/offices' onSelect={setShippingOffice} selected={ShippingOffice} label='Shipping Address' placeholder='Search Office' value=''/>
                </Grids.Column>
            </Grids.Row>
            <div className='mt4 border-top'/>
            <Headings.Regular className='mt3'>Add Items</Headings.Regular>
            <Texts.Small>Add Items / service to your invoice</Texts.Small>
            <table className='simple-table mt3'>
                <thead><tr><th>Name</th><th>Qty</th><th>Price</th><th>Tax</th><th></th></tr></thead>
                <tbody>
                    {   
                        LineItems.length?
                        LineItems.map(((item, index) => {
                            return (
                                <tr key={index}>
                                    <td>{item.name}</td>
                                    <td>{item.qtty}</td>
                                    <td>{item.unitPrice}</td>
                                    <td>{item.tax}</td>
                                    <td onClick={()=>removeItem(item.name)}>
                                        <i className="fas fa-trash-alt"></i> Delete
                                    </td>
                                </tr>
                            )
                        }))
                        :<tr>
                            <td colSpan={4} style={{textAlign: 'start'}}>No Items Added</td>
                        </tr>
                    }
                </tbody>
            </table>
            <Button onClick={()=>Category?setAddModal(true):TOAST.error('Category is required to add items')} className='btn-primary btn-sm'>+ Add Item</Button>

            <div className='mt4 border-top'/>
            <Headings.Regular className='mt3'>Amount</Headings.Regular>
            <Texts.Small>Tax and amount is calculated from items</Texts.Small>

            <Grids.Row className='mt2'>
                <Grids.Column grid={{mobile: 6, medium: 3, large: 3}}>
                    <Input onChange={(val)=>{
                        val = Number(val)
                        if(val && val<101){
                            setDISCOUNT(val)
                        }else{
                            setDISCOUNT(0)
                        }
                    }} min={0} max={100} className='mr1 mt1' label='Discount (Applied before tax)' placeholder='Discount %' type='number'/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 6, medium: 3, large: 3}}>
                    <Input disabled={true} defaultValue={`${Sgst}`} ref={SGST} className='mr1 mt1' label='SGST/UGST' placeholder='SGST/UGST' type='number'/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 6, medium: 3, large: 3}}>
                    <Input disabled={true} defaultValue={`${Igst}`} ref={IGST} className='mr1 mt1' label='IGST' placeholder='IGST' type='number'/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 6, medium: 3, large: 3}}>
                    <Input disabled={true} defaultValue={`${Cgst}`} ref={CGST} className='mr1 mt1' label='CGST' placeholder='CGST' type='number'/>
                </Grids.Column>
            </Grids.Row>
            
            <Grids.Row>
                <Grids.Column grid={{mobile: 6, medium: 3, large: 3}}>
                    <Input disabled={true} defaultValue={`${Base}`} ref={BASE} className='mr1 mt1' label='Base Amount' placeholder='Base Amount' type='number'/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 6, medium: 3, large: 3}}>
                    <Input disabled={true} defaultValue={`${Total}`} ref={TOTAL} className='mr1 mt1' label='Total Amount' placeholder='Total Amount' type='number'/>
                </Grids.Column>
            </Grids.Row>
            <Button onClick={submitInvoice} className='btn-black btn-lg mt3'>Submit<i className="ml1 fas fa-long-arrow-alt-right"></i></Button>
        </Grids.Container>

        {
            AddModal&&
            <Modal title='Add Items' description='Add items in invoice' onSubmit={addLineItem} onClose={()=>setAddModal(undefined)} button='Add'>
                <Grids.Row>
                    <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                        <SearchableSelect className='mt1 mr1' list={AllServices} onSelect={setService} selected={Service} label='Service' placeholder='Select Service' value=''/>
                    </Grids.Column>
                    <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                        <Input ref={NAME} className='mr1' label='Name' placeholder='Product Name' type='text'/>
                    </Grids.Column>
                </Grids.Row>
                <Grids.Row>
                    <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                        <Input ref={QTY} className='mr1' label='Quantity' placeholder='Quantity' type='number'/>
                    </Grids.Column>
                    <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                        <Input ref={PRICE} className='mr1' label='Price' placeholder='Price' type='number'/>
                    </Grids.Column>
                </Grids.Row>
                <Grids.Row>
                    <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                        <SelectInput ref={TAX} className='mr1' label='Tax' placeholder='Select GST' options={[{value:0,label:"0 %"},{value:2,label:'2 %'},{value:5,label:'5 %'},{value:10,label:'10 %'},{value:12,label:'12 %'},{value:18,label:'18 %'},{value:28,label:'28 %'},{value:30,label:'30 %'}]}/>
                    </Grids.Column>
                </Grids.Row>
            </Modal>
        }
        </>
    );
}

export default Submit;