import React from 'react';
import { Button } from '../../../../retro';
import './Invoice.css'
import {Headings} from '../../../../retro';
import moment from 'moment';
import '../../../../retro/Animation/Animation.css'

function InvoiceCard({amount, category, currency, date, dueDate, id, invoiceId, office, actions, onApprove, onDecline, onClick}) {
    return (
        <div onClick={onClick} className='FadeInUp-Effect invoice-card'>
            <div>
                <div className='flex justify-between'>
                    <Headings.Regular className='fw-bold'>{currency} {amount}</Headings.Regular>
                    <div className='text-small'><i className="fas fa-external-link-alt"/> Open</div>
                </div>
                <div className='fw-bold text-truncate text-small'>{invoiceId}</div>
                <div className='mt1 mb1' style={{borderBottom: '1px solid var(--theme-border-color'}}/>
                <div className='text-truncate text-small'>Category: {category}</div>
                {dueDate&&<div className='text-truncate text-small'>Due Date: {moment(dueDate).format('DD MMM YY')}</div>}
                <div className='text-truncate text-small'>Invoice Date: {moment(date).format('DD MMM YY')}</div>
                <div className='text-truncate text-small'>Office: {office}</div>
            </div>
            {
                actions&&
                <div className='mt1 flex center'>
                    <Button onClick={e=>{e.stopPropagation();onApprove()}} className='approve-btn btn-sm mr1 flex-fill' margin='mt1'><i className="fas fa-check"/> Forward</Button>
                    <Button onClick={e=>{e.stopPropagation();onDecline()}} className='decline-btn btn-sm flex-fill' margin='mt1'><i className="fas fa-times"/> Decline</Button>
                </div>
            }
        </div>
    );
}

export default InvoiceCard;