import React from 'react';

function Tour() {
    return (
        <>
        <iframe title="Tour" src="https://capture.navattic.com/cl7oxc5ak5430809layj0qylsf" style={{position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, width: '100%', height: '100%', margin: 0, padding: 0, zIndex: 999999}} frameBorder={0} />
        </>
    );
}

export default Tour;