import React from 'react';
import {Texts} from "../../../../retro";
import { useHistory } from 'react-router-dom';
import moment from 'moment';

function Updates({invoice, approval}) {

	let HISTORY = useHistory();

    return (
            <div className='mt1 flex flex-wrap scrollbar-hidden' style={{marginLeft: '-0.7rem'}}>
                 {
                    invoice&&
                    <div onClick={()=>HISTORY.push(`invoices?invoice=${invoice.id}`)} className='pd3 rounded-md margin-1 pointer border flex-fill' style={{width:150}}>
                        <Texts.Regular className='fw-bold'>
                            <i className="fas fa-receipt mr1"></i>
                            Invoice
                        </Texts.Regular>
                        <Texts.Small className='mt1'>
                            You have new invoice, ID <b>{invoice.invoiceId}</b> of amount {invoice.currency} {invoice.amount} on {moment(invoice.date).format('DD MMM YY')}.
                        </Texts.Small>
                    </div>
                 }
                {
                    approval&&
                    <div onClick={()=>HISTORY.push(`approvals?approval=${approval.id}`)} className='pd3 rounded-md margin-1 pointer border flex-fill' style={{width:150}}>
                        <Texts.Regular className='fw-bold'>
                            <i className="fas fa-sitemap mr1"></i>
                            Approval
                        </Texts.Regular>
                        <Texts.Small className='mt1'>
                            You have new approval request title <b>{approval.title}</b> by {approval.asker} on {moment(approval.createdAt).format('DD MMM YY')}.
                        </Texts.Small>
                    </div>
                }
                {
                    !approval&&!invoice?
                    <div onClick={()=>HISTORY.push(`approvals?approval=${approval.id}`)} className='pd3 rounded-md margin-1 pointer border'>
                        <Texts.Regular>
                            <i className="fas fa-check-circle mr1"></i>
                            No new notifications
                        </Texts.Regular>
                    </div>
                    :<></>
                }
            </div>
    );
}

export default Updates;