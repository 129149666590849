import React, {useEffect, useState} from 'react';
import {get} from '../../../Network/Axios'
import {Button, Grids, Headings, Loaders, SearchBox, Thumbnail, PageLoad, Modal} from '../../../../retro'
import './Support.css'
import moment from 'moment';
import Chat from './Chat'
import {useHistory, useLocation} from 'react-router-dom'
import AddTicket from './Add';

function Support() {

    const [Tickets, setTickets] = useState([]);
    const [FilteredTickets, setFilteredTickets] = useState([]);
    const [NewModal, setNewModal] = useState(undefined);

    const [Loading, setLoading] = useState(true);

    let HISTORY = useHistory();
    let LOCATION = useLocation()
    let pathname = LOCATION.pathname;
    let params = new URLSearchParams(LOCATION.search)
	let ticketId = params.get('ticket')

    useEffect(() => {
        fetchPending()
    }, []);

    const fetchPending = (params) => {
        setLoading(true)
        get(`/support/pending`, (e,r)=>{
            if(r){
                setTickets(r.tickets)
                setFilteredTickets(r.tickets)
                setLoading(false)
                PageLoad(`Support`)
            }
        })
    }

    const filterTickets = (val) => {
        val = val.toLowerCase();
        if(val){
            let filtered = Tickets.filter((ticket)=>(ticket.title.toLowerCase().includes(val)||ticket.category.toLowerCase().includes(val)))
            setFilteredTickets(filtered)
        }else{
            setFilteredTickets(Tickets)
        }
    }

    const renderTickets = (ticket, index) => {

        return (
            <div onClick={()=>HISTORY.push(`${pathname}?ticket=${ticket.id}`)} key={index} className='ticket'>
                <div className='mr1'><Thumbnail letter={'S'} size='L'/></div>
                <div className='details'>
                    <div className='flex justify-between'>
                        <div>
                            <div className='fw-bold text-truncate'>{ticket.title}</div>
                            <div className='text-truncate'>{ticket.category}</div>
                        </div>
                        <div className='flex column' style={{alignItems:'flex-end'}}>
                            <div>{moment(ticket.createdAt).format('DD MMM YY')}</div>
                            {/* <div className='count-badge' style={{background: 'var(--theme-primary-color)', marginTop: 5}}>1</div> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <Grids.Row className='h-100'>
            <Grids.Column grid={{mobile: 12, medium: 5, large: 5}} className='h-100 pr-md-2'>
                <div className='pr-md-2 h-100 overScroll'>
                    <div className='flex justify-between align-center pl6 pl-md-0'>
                        <Headings.Medium>Support</Headings.Medium>
                        <Button onClick={()=>{setNewModal(true)}} className='btn-black btn-sm' margin='mt0'><i className="far fa-edit w-"></i> New</Button>
                    </div>
                    <SearchBox className='mt3' placeholder='Search' onChange={filterTickets}/>
                    {
                        Loading?
                        <div className='text-center'><Loaders.Medium/></div>
                        :
                        <>
                        <div className='support mt3'>
                            <div className='FadeInUp-Effect rounded-sm border hidden'>
                                {   
                                    FilteredTickets.length?
                                    FilteredTickets.map(renderTickets)
                                    :<div className='text-small text-center pd3'>
                                    <i className="fas fa-tag mr1"></i> No Support tickets
                                    </div>
                                }
                            </div>
                        </div>
                        </>
                    }
                </div>
            </Grids.Column>
            <Grids.Column className='rounded-sm hidden' grid={{mobile: 12, medium: 7, large: 7}}>
                {
                    ticketId
                    ?<Chat id={ticketId} onClose={()=>HISTORY.push(pathname)}/>
                    :<div className='display-none display-md h-100'><AddTicket onReload={()=>fetchPending(ticketId)}/></div>
                }
                {
                    NewModal?
                    <Modal title='New Ticket' onClose={()=>setNewModal(false)}>
                        <AddTicket onReload={()=>{setNewModal(false);fetchPending(ticketId)}}/>
                    </Modal>
                    :<></>
                }
            </Grids.Column>
        </Grids.Row>
    );
}

export default Support;