import React, {useState, useRef, useEffect} from 'react';
import {Grids, SearchInput, SearchableSelect, Input, TOAST, Headings, FileInput, Attachment, DateInput, Button} from '../../../../retro'
import {get} from '../../../Network/Axios'
import moment from 'moment';

function Submit({updatePreview, mailId, onSubmit, update}) {

    ;

    const [AllServices, setAllServices] = useState([]);

    const [Service, setService] = useState(undefined);
    const [Vendor, setVendor] = useState(undefined);

    const [BillingOffice, setBillingOffice] = useState(undefined);
    const [ShippingOffice, setShippingOffice] = useState(undefined);

    const [Attachments, setAttachments] = useState([]);

    let INVOICE_ID = useRef(), DATE = useRef(), DUE = useRef(), SGST = useRef(), CGST = useRef(), IGST = useRef(), BASE = useRef(), TOTAL = useRef();
    
    //Updating Preview
    useEffect(() => {
        if(updatePreview){
            updatePreview({
                vendor: Vendor,
                billing: BillingOffice,
                shipping: ShippingOffice,
                attachment: Attachments
            })
        }
    }, [Vendor, BillingOffice, ShippingOffice, updatePreview, Attachments]);

    //update prop to update Invoice from outside
    useEffect(() => {
        if(update){
            update(updateDetails)
        }
    }, [update]);

    const updateDetails = (obj) => {
        if(obj.attachments){
            setAttachments(prev=>[...prev, ...obj.attachments])
        }
    }

    useEffect(()=>{
        get(`search/services`, (e, r) => {
            if (r) {
                setAllServices(r.items.map(val=>({
                    name:val.name,
                    id:val.id
                })))
            }else{
                TOAST.handleError(e)
            }
        })

    },[])

    const removeAttachment = (file) => {
        let files = Attachments.filter((attachment=>attachment!==file))
        setAttachments(files)
    }

    let submitInvoice = () => {

        let vendor = Vendor&&Vendor.addresses[0].address;
        let billNumber = INVOICE_ID.current.value;
        let billDate = DATE.current.value;
        let dueDate = DUE.current.value;
        let base = BASE.current.value;
        let igst = IGST.current.value;
        let cgst = CGST.current.value;
        let sgst = SGST.current.value;
        let total = TOTAL.current.value;

        if(!Service) {
            TOAST.error('Please select a service') 
            return;
        }

        if(!vendor) {
            TOAST.error('Please select the Vendor address') 
            return;
        }

        if(!BillingOffice) {
            TOAST.error('Please select the Billing Office') 
            return;
        }

        if(!ShippingOffice) {
            TOAST.error('Please select the Shipping Office') 
            return;
        }

        if(!billNumber) {
            TOAST.error('Please enter the Bill Number')
            return;
        }

        if(!billDate) {
            TOAST.error('Please select the Bill Date')
            return;
        }

        if(!dueDate) {
            TOAST.error('Please select the Due Date')
            return;
        }

        if(!base) {
            TOAST.error('Please enter the Base amount')
            return;
        }

        if(!igst) {
            TOAST.error('Please enter the IGST amount')
            return;
        }

        if(!cgst) {
            TOAST.error('Please enter the CGST amount')
            return;
        }

        if(!sgst) {
            TOAST.error('Please enter the SGST amount')
            return;
        }

        if(!total) {
            TOAST.error('Please enter the TOTAL amount')
            return;
        }

        if(billDate) {
            billDate = moment(moment(billDate,'YYYY-MM-DD')).valueOf();
            dueDate = moment(moment(dueDate,'YYYY-MM-DD')).valueOf();
        }

        let data  = {
            addressId: Vendor.registeredId,
            billTo: {...BillingOffice, value: BillingOffice.id, label: BillingOffice.name},
            shipTo: {...ShippingOffice, value: ShippingOffice.id, label: ShippingOffice.name},
            vendor: {...Vendor, value: Vendor.id, label: Vendor.name},
            billNumber,
            base:+base,
            igst:+igst,
            cgst:+cgst,
            sgst:+sgst,
            total:+total,
            billDate,
            dueDate,
            attachment: Attachments,
            service: Service.id,
            mailId: mailId?mailId:''
        }

        onSubmit(data, 'single')
    }

    return (
        <>
        <Grids.Container style={{margin:0}}>

            <Headings.Regular>Attachments</Headings.Regular>
            <div className='flex align-center flex-wrap mt1' style={{marginLeft:'-0.25rem'}}>
                <FileInput updateFiles={(file)=>{setAttachments(prev=>[...prev, file])}}/>
                {
                    Attachments.map((file, index)=>{
                        return (
                            <Attachment onDelete={removeAttachment} key={index} url={file}/>
                        )
                    })
                }
            </div>

            <div className='mt4 border-top'/>

            <Headings.Regular className='mt3'>Vendor</Headings.Regular>
            <Grids.Row>
                <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                    <SearchableSelect className='mr1 mt1' list={AllServices} onSelect={setService} selected={Service} label='Select Service' placeholder='Select Service' value=''/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                    <SearchInput searchKey='title' className='mr1 mt1' api='/search/vendor' onSelect={setVendor} selected={Vendor} label='Select Vendor' placeholder='Search Vendor' value=''/>
                </Grids.Column>
            </Grids.Row>

            <div className='mt4 border-top'/>

            <Headings.Regular className='mt3'>Billing to</Headings.Regular>
            <Grids.Row>
                <Grids.Column grid={{mobile: 6, medium: 6, large: 6}}>
                    <Input ref={INVOICE_ID} className='mr1 mt1' label='Bill Number' placeholder='Bill Number' type='text'/>
                </Grids.Column>
            </Grids.Row>
            <Grids.Row>
                <Grids.Column grid={{mobile: 6, medium: 6, large: 6}}>
                    <DateInput ref={DATE} className='mr1 mt1' label='Invoice Date'/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 6, medium: 6, large: 6}}>
                    <DateInput ref={DUE} className='mr1 mt1' label='Due Date'/>
                </Grids.Column>
            </Grids.Row>
            <Grids.Row>
                <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                    <SearchInput className='mr1 mt1' api='/search/offices' onSelect={setBillingOffice} selected={BillingOffice} label='Billing Address' placeholder='Search Office' value=''/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                    <SearchInput className='mr1 mt1' api='/search/offices' onSelect={setShippingOffice} selected={ShippingOffice} label='Shipping Address' placeholder='Search Office' value=''/>
                </Grids.Column>
            </Grids.Row>

            <div className='mt4 border-top'/>

            <Headings.Regular className='mt3'>Amount</Headings.Regular>
            <Grids.Row>
                <Grids.Column grid={{mobile: 6, medium: 4, large: 4}}>
                    <Input ref={SGST} className='mr1 mt1' label='SGST/UGST' placeholder='SGST/UGST' type='text'/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 6, medium: 4, large: 4}}>
                    <Input ref={IGST} className='mr1 mt1' label='IGST' placeholder='IGST' type='text'/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 6, medium: 4, large: 4}}>
                    <Input ref={CGST} className='mr1 mt1' label='CGST' placeholder='CGST' type='text'/>
                </Grids.Column>
            </Grids.Row>
            <Grids.Row>
                <Grids.Column grid={{mobile: 6, medium: 4, large: 4}}>
                    <Input ref={BASE} className='mr1 mt1' label='Base Amount' placeholder='Base Amount' type='text'/>
                </Grids.Column>
                <Grids.Column grid={{mobile: 6, medium: 4, large: 4}}>
                    <Input ref={TOTAL} className='mr1 mt1' label='Total Amount' placeholder='Total Amount' type='text'/>
                </Grids.Column>
            </Grids.Row>
            <Button onClick={submitInvoice} className='btn-black btn-lg mt3'>Submit<i className="ml1 fas fa-long-arrow-alt-right"></i></Button>
        </Grids.Container>
        </>
    );
}

export default Submit;