import React, {useState, useEffect, useRef} from 'react';
import {TOAST, SidePane, StatelessTab, TabBody, PageLoad, Modal, Button, Input} from '../../../../../retro'
import {get, post} from '../../../../Network/Axios'
import Forms from './Forms'
import Format from './Format'
import Insights from './Insights'
import Timeline from './Timeline'
import PreviewAttachment from './PreviewAttachment'
import PODetails from './PODetails';

function View({onClose, id, disabled, onReload}) {

    let FORM = useRef(), Remarks = useRef();

    const [ActiveTab, setActiveTab] = useState('Format');
    const [Details, setDetails] = useState({renderer: 'INDIA'});

    const [BtnLoader, setBtnLoader] = useState(false);
    const [DeclineModal, setDeclineModal] = useState(undefined);
    const [ApproveModal, setApproveModal] = useState(undefined);

    useEffect(() => {
        fetchInvoice(id)
    },[id]);

    
    const fetchInvoice = (ID)=>{
		get(`invoices/${ID}/details`,(e,r)=>{
			if(r){
                setDetails(r);
                PageLoad(`Open Invoice ${ID}`)
			}else if(e){
				TOAST.handleError(e)
			}
		})
	}

    const forward = (ID)=>{
        
        let forms  = undefined
        if(Details.forms && Details.forms.length){
            if(!FORM.current){
                TOAST.error('Fill the Form before Forwarding')
                return
            }
            forms  = FORM.current()//Getting custom form values
            if(!forms){
                TOAST.error('Fill the Form for Approval')
                return
            }
        }

        setBtnLoader(true)
        post(`/invoices/${ID}/next`, {forms}, (e, r) => {
			if (r) {
                onReload()
                setBtnLoader(false)
                TOAST.success('Invoice Forwarded')
                onClose()
			} else if (e) {
				TOAST.handleError(e)
                setBtnLoader(false)
			}
		});
    }

    const decline = (ID)=>{
        let remarks = Remarks.current.value;
        if(!remarks){
            TOAST.error('Remarks is required')
            return;
        }
        setBtnLoader(true)
        post(`/invoices/${ID}/cancel`, {remarks}, (e, r) => {
			if (r) {
                onReload()
                setBtnLoader(false)
				TOAST.success('Invoice Declined')
			} else if (e) {
				TOAST.handleError(e)
                setBtnLoader(false)
			}
		});
    }

    const TabOptions = ['Format', 'Insights', 'Timeline']
    
    if(Details.po){
        TabOptions.push('PO Details')
    }


    return (
        <>
        <SidePane title='Invoice' description={Details.invoice&&Details.invoice.counterId} onClose={onClose}
        tabs={<StatelessTab style={{marginBottom:'-1rem', border:'none'}} active={ActiveTab} onClick={setActiveTab} top='3.87rem' tabs={TabOptions}/>}
        preview={<PreviewAttachment invoiceId={id} details={Details} refresh={()=>{fetchInvoice(id)}}/>}
        actions={disabled?<></>:
            <div className='flex center'>
                <Button loading={BtnLoader} onClick={()=>forward(id)} className='approve-btn mr1 flex-fill' margin='mt1'><i className="fas fa-check"/> Forward</Button>
                <Button onClick={()=>setDeclineModal(id)} className='decline-btn flex-fill' margin='mt1'><i className="fas fa-times"/> Decline</Button>
            </div>
        }
        >
            <TabBody active={ActiveTab} label='Format'>
                <Format disabled={disabled} onReload={()=>fetchInvoice(id)} id={id} details={Details}/>
                {
                    Details && Details.forms && Details.forms.length?
                    <div className='w-100 h-100 mt4 relative overScroll'>
                        <Forms forms={Details?Details.forms:[]} handleRef={func=>FORM.current=func}/>
                    </div>:<></>
                }
            </TabBody>
            <TabBody active={ActiveTab} label='Insights'>
                <Insights tags={Details.tags} items={Details.invoice?Details.invoice.items:[]}/>
            </TabBody>
            <TabBody active={ActiveTab} label='Timeline'>
                <Timeline timeline={Details.timelines}/>
            </TabBody>
            <TabBody active={ActiveTab} label='PO Details'>
                <PODetails po={Details.po}/>
            </TabBody>
        </SidePane>
        {ApproveModal&&<Modal title='Forward Invoice' description='Are you sure you want to forward this Invoice?' onSubmit={()=>forward(ApproveModal)} onClose={()=>setApproveModal(undefined)} button='Yes, Forward'/>}
        {DeclineModal&&
        <Modal title='Decline Invoice' description='Are you sure you want to decline this Invoice?' onSubmit={()=>decline(DeclineModal)} onClose={()=>setDeclineModal(undefined)} button='Decline'>
            <Input label='Remarks' placeholder='Enter Remarks' ref={Remarks}/>
        </Modal>
        }
        </>
    );
}

export default View;