import React from 'react';
import {Grids, DeltaInput, SearchInput, TOAST} from '../../../../retro'
import {post} from '../../../Network/Axios'
import TDSSelection from './TDSSelection'

function Update({onReload, id, invoice={}, taxInformation={}, address={}, deliverTo={}, disabled}) {

    

    const updateInvoice = (field, value) => {

		let key = '';

		switch (field) {
			case 'vendor':
				post(`/invoices/${id}/vendor.change`, {vendor: value}, (e, r) => {
					if (r) {
						onReload()
                        TOAST.success('Vendor Updated')
					} else if (e) {
						TOAST.handleError(e);
					}
				});
				return;
			case 'id': key = 'invoice';break;
			case 'bill':
				post(`/invoices/${id}/change`, {key: 'billing',value: String(value)}, (e, r) => {
					if (r) {
						onReload()
						TOAST.success('Billing Address Updated')
					} else if (e) {
						TOAST.handleError(e);
					}
				});
				return;
			case 'ship':
				post(`/invoices/${id}/change`, {key: 'address',value: String(value)}, (e, r) => {
					if (r) {
						onReload()
						TOAST.success('Shipping Address Updated')
					} else if (e) {
						TOAST.handleError(e);
					}
				});
				return;

			case 'base': key = 'base';break;
			case 'tds': key = 'tds';break;
			case 'tdsFixed': key = 'tds.fixed';break;
			case 'sgst': key = 'sgst';break;
			case 'igst': key = 'igst';break;
			case 'cgst': key = 'cgst';break;
			default: return;
		}

		post(`/invoices/${id}/change`, {'key': key, 'value': value}, (e, r) => {
			if (r) {
				onReload()
				TOAST.success('Field Updated')
			} else if (e) {
				TOAST.handleError(e);
			}
		});
	}

    return (
        <>
        <Grids.Row>
            <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                <SearchInput disabled={disabled} morph={true} className='mr1 mt3' api='/search/offices' onSelect={(val)=>{updateInvoice('bill',val.id)}} selected={{name: address.title}} label='Billing Address' placeholder='Search Office' value=''/>
            </Grids.Column>
            <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                <SearchInput disabled={disabled} morph={true} className='mr1 mt3' api='/search/offices' onSelect={(val)=>{updateInvoice('ship',val.id)}} selected={{name: deliverTo.title}} label='Shipping Address' placeholder='Search Office' value=''/>
            </Grids.Column>
        </Grids.Row>

        <Grids.Row>
            <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                <DeltaInput disabled={disabled} morph={true} className='mr1 mt3' label='Invoice ID' defaultValue={invoice.invoiceId} type='text' onUpdate={(val)=>{updateInvoice('id',val)}}/>
            </Grids.Column>
            <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
				<TDSSelection onSubmit={(type,val)=>{updateInvoice(type,val)}} taxInformation={taxInformation} disabled={disabled}/>
            </Grids.Column>
        </Grids.Row>

        <Grids.Row>
            <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                <DeltaInput disabled={disabled} morph={true} className='mr1 mt3' label='SGST/UGST' defaultValue={parseInt(taxInformation.sgst) + parseInt(taxInformation.utgst)} type='number' onUpdate={(val)=>{updateInvoice('sgst',val)}}/>
            </Grids.Column>
            <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                <DeltaInput disabled={disabled} morph={true} className='mr1 mt3' label='IGST' defaultValue={taxInformation.igst} type='number' onUpdate={(val)=>{updateInvoice('igst',val)}}/>
            </Grids.Column>
        </Grids.Row>
        <Grids.Row>
            <Grids.Column grid={{mobile: 12, medium: 6, large: 6}}>
                <DeltaInput disabled={disabled} morph={true} className='mr1 mt3' label='CGST' defaultValue={taxInformation.cgst} type='number' onUpdate={(val)=>{updateInvoice('cgst',val)}}/>
            </Grids.Column>
        </Grids.Row>
        </>
    );
}

export default Update;