import React, {useState, useEffect} from 'react';
import SearchBox from '../../../../../retro/Inputs/SearchBox';
import {get} from '../../../../Network/Axios'
import {Empty, Loaders, PageLoad} from '../../../../../retro'
import InvoiceCard from '../../../../Components/Cards/Invoice/Invoice'
import ViewInvoice from '../View'
import { useHistory, useLocation } from 'react-router-dom';

function Pending() {

    let HISTORY = useHistory();
    let LOCATION = useLocation()
    let pathname = LOCATION.pathname;
    let params = new URLSearchParams(LOCATION.search)
	let InvoiceId = params.get('invoice')
    
    const [Invoices, setInvoices] = useState([]);
    const [FilteredInvoices, setFilteredInvoices] = useState([]);
    const [Loading, setLoading] = useState(true);

    useEffect(() => {
        fetchInvoices()
    }, []);

    const fetchInvoices = () => {
        get(`/invoices/pending`, (e,r)=>{
            if(r){
              	setInvoices(r.invoices)
                setFilteredInvoices(r.invoices)
                setLoading(false)
                PageLoad('Pending Invoices')
            }
        })
    }

    const filterInvoices = (val) => {
        val = val.toLowerCase();
        if(val){
            let filtered = Invoices.filter((invoice)=>(invoice.invoiceId.toLowerCase().includes(val)||invoice.category && invoice.category.toLowerCase().includes(val)|| invoice.office && invoice.office.toLowerCase().includes(val)))
            setFilteredInvoices(filtered)
        }else{
            setFilteredInvoices(Invoices)
        }
    }

    return (
        <>
        <SearchBox placeholder='Search' onChange={filterInvoices}/>
        <div className='flex flex-wrap mt3 w-100'>
            {
                Loading?<div className='w-100 text-center'><Loaders.Medium/></div>:
                FilteredInvoices.length?
                FilteredInvoices.map((invoice, index)=>{
                    return (
                        <InvoiceCard onClick={()=>HISTORY.push(`${pathname}?invoice=${invoice.id}`)} key={index} {...invoice}/>
                    )
                })
                :<Empty label='No Pending Invoices'/>
            }
        </div>
        {InvoiceId&&<ViewInvoice onReload={()=>fetchInvoices()}  id={InvoiceId} onClose={()=>HISTORY.push(pathname)}/>}
        </>
    );
}

export default Pending;