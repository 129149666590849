import React from 'react';
import { CustomForm } from '../../../../../retro';

function Form({forms, handleRef}) {
    return (
        <>
        <div className='fw-bold mb1'>CUSTOM FORM</div>
        <div className='text-small mb3'>
            <i className='fa fa-info-circle mr1'/>
            Fill the Form before submitting for Approval
        </div>
        <CustomForm forms={forms} handleRef={handleRef}/>
        </>
    );
}

export default Form;