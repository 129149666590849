import React from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Login from './Pages/Login'
import {Provider} from 'react-redux';
import './Assets/styles.css'
import Loading from './Pages/Login/Loading'
import Store from "./Redux";
import App from './Pages/App'
import {MainContainer, CheckInternet, Button, Loaders} from "../retro";
import {useLoginHook} from "./Hooks/useLoginHook";
import useConfigHook from "./Hooks/useConfigHook";
import Tour from './Tour/Tour'

const ReduxStore = Store();

function AppWrapper() {
	const LOGIN = useLoginHook();
	const CONFIG = useConfigHook();

	if (!LOGIN.authenticated) {
		return (
			<MainContainer>
			<BrowserRouter>
				<Switch>
					<Route path="/success" component={Loading} exact/>
					<Route path="/login" component={Login} exact/>
					<Route path="*" component={Login}/>
				</Switch>
			</BrowserRouter>
			</MainContainer>
		)
	}
	if (CONFIG.loading) {
		return (
			<div className="flex column h-100h w-100w center">
				<Loaders.Medium/>
				<Button onClick={()=>{
					localStorage.clear()
					window.location.reload();
				}} className='fixed btn-sm text-small btn-black' style={{top:'0rem', right:'1rem'}}>Logout</Button>
			</div>
		)
	}
	return (
		<MainContainer>
			<BrowserRouter>
				<Switch>
					<Route path="/tour" component={Tour} exact/>
					<Route path="/" component={App}/>
				</Switch>
			</BrowserRouter>
		</MainContainer>
	)
}

function Index() {
	return (
		<Provider store={ReduxStore}>
			<CheckInternet>
				<AppWrapper/>
			</CheckInternet>
		</Provider>
	);
}

export default Index;
