import React from 'react';
import './Invoice.css'
import { Headings, Loaders } from '../../../../retro';
import Item from './Item';

function Preview({address, category,categoryId, chips, content,refresh, deliverTo,invoiceId, dueOn, duplicates, invoice, officeName, owner, renderer, tags, taxInformation, timelines, type, vendor, view, style}) {

    if(!invoice){
        return <div className='w-100 text-center'><Loaders.Medium/></div>
    }
    return(
            <div className='FadeInUp-Effect text-small w-100 h-100 overScroll pd4' style={style}>
                <Headings.Regular className='text-center'>INVOICE</Headings.Regular>
                <div className='border pd2 rounded-sm mt2'>
                    <div className='flex justify-between'>
                        <div style={{textAlign: 'left'}}>
                            <b>Vendor</b><br/>
                            {vendor.name}<br/>
                            Email: {vendor.email}<br/>
                            GSTIN: {vendor.gstin}
                        </div>
                        <div style={{textAlign: 'right'}}>
                            Date: {invoice.date}<br/>
                            Invoice ID: {invoice.invoiceId}<br/>
                        </div>
                    </div>
                    <div className='flex justify-between mt2'>
                        <div style={{textAlign: 'left'}}>
                            <b>Billing Address</b><br/>
                            {address.title}, {address.address}, {address.city} <br/>
                            GSTIN: {address.gstin}
                        </div>
                        <div style={{textAlign: 'right'}}>
                            <b>Shipping Address</b><br/>
                            {deliverTo.title}, {deliverTo.address}, {deliverTo.city} <br/>
                            GSTIN: {deliverTo.gstin}
                        </div>
                    </div>
                </div>
                
                <table className='simple-table'>
                    <thead><tr><th>Description</th><th>Base Amt</th><th>Qty</th><th>Tax</th><th>Amount (INR)</th></tr></thead>
                    <tbody>
                        {   
                            invoice.items.length?
                            invoice.items.map(((item, index) => <Item categoryId={categoryId} refresh={refresh} invoiceId={invoiceId} key={index} item={item} />))
                            :<tr>
                                <td colSpan={4} style={{textAlign: 'start'}}>No Items</td>
                            </tr>
                        }
                    </tbody>
                </table>

                <div className='invoice-taxes'>
                    <div className='wrap'>
                        <div>CGST: </div>
                        <div> {taxInformation.cgst?taxInformation.cgst.toFixed(2):0}</div>
                    </div>
                    <div className='wrap'>
                        <div>SGST / UTGST: </div>
                        <div> {parseInt(taxInformation.sgst) + parseInt(taxInformation.utgst)}</div>
                    </div>
                    <div className='wrap'>
                        <div>IGST: </div>
                        <div> {taxInformation.igst?taxInformation.igst.toFixed(2):0}</div>
                    </div>
                    <div className='wrap'>
                        <div>Tax Amt: </div>
                        <div> {taxInformation.gst?taxInformation.gst.toFixed(2):0}</div>
                    </div>
                    <div className='wrap'>
                        <div>Base Amt: </div>
                        <div> {taxInformation.baseAmount}</div>
                    </div>
                    <div className='wrap'>
                        <div>Total Amt: </div>
                        <div> {parseInt(taxInformation.baseAmount) + parseInt(taxInformation.gst)}</div>
                    </div>
                    <div className='wrap'>
                        <div>TDS Amt: </div>
                        <div> {taxInformation.tds}</div>
                    </div>
                    <div className='wrap'>
                        <div>Discount Amt: </div>
                        <div> {taxInformation.discountAmount}</div>
                    </div>
                </div>

                <div className='flex fw-bold' style={{padding: '0.5rem 0px', borderTop: '1px solid #000', borderBottom: '1px solid #000'}}>
                    <div style={{flexGrow: 1, textAlign: 'left'}}>PAYABLE AMOUNT</div>
                    <div style={{width: '100px', textAlign: 'right'}}> {taxInformation.paidAmount}</div>
                </div>
            </div>
    )
}

export default Preview;