import React from 'react';
import {Loaders} from '../../../retro'

function Loading(props) {
    return (
        <div style={{width: '100%', background: '#fff', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Loaders.Medium/>
        </div>
    );
}

export default Loading;