import {axios} from "./Axios";
import * as Crypto from "../Utils/Crypto";
import qs from 'qs'
import {parseJson} from "../Utils/Utils";

class Wrapper{

	getUrl = () => {
		const token = Crypto.get('token');
		if(!token)
		return "https://heimdall.eka.io/scanners/";
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));
		let json = JSON.parse(jsonPayload);
		if(!json.origin){
			return "https://heimdall.eka.io/scanners/";
		}
		return json.origin + "scanners/";
	}

	Cancellable=(promise, mCancel = false)=>{
		let hasCompleted = false;
		const wrappedPromise = new Promise((resolve, reject) => {
			promise.then(
				val => {
					if (hasCompleted) {
						reject({ isCanceled: true })
					} else {
						hasCompleted = true;
						resolve(val)
					}
				},
				error => {
					if (hasCompleted) {
						reject({ isCanceled: true })
					} else {
						reject(error);
					}
				}
			);
		});
		return {
			promise: wrappedPromise,
			cancel() {
				hasCompleted = true;
				if (mCancel) {
					mCancel()
				}
			},
			isCancellable() {
				return !hasCompleted;
			}
		};
	};



	get=(url, cb, data = {})=>{
		if(url.startsWith("/")){
			url = url.substr(1,url.length)
		}
		let self = this;
		let cancel = false;
		let cancelToken = new axios.CancelToken(function executor(c) {
			cancel = c;
		});
		let cancellable = self.Cancellable(
			axios.get(`${this.getUrl()}${url}`, {
				params: data,
				paramsSerializer: params => {
					return qs.stringify(params, { arrayFormat: 'repeat' })
				},
				cancelToken
			}), cancel
		);
		cancellable.promise.then(res => {
			if (url.includes(".csv")) {
				cb(null, parseJson(res.data))
			} else {
				cb(null, res.data)
			}
		}).catch(err => {
			if (err && !err.isCanceled) {
				cb(err)
			}
		});
		return cancellable;
	}


	delete = (url, cb,data={}) => {
		if(url.startsWith("/")){
			url = url.substr(1,url.length)
		}
		let self = this;
		let cancellable = self.Cancellable(
			axios.delete(`${self.getUrl()}${url}`, {
				data
			})
		);
		cancellable.promise.then(res => {
			cb(null, res.data)
		}).catch(err => {
			if (err && !err.isCanceled) {
				cb(err)
			}
		});
		return cancellable;
	}

	post=(url, data, cb)=>{
		if(url.startsWith("/")){
			url = url.substr(1,url.length)
		}
		let self = this;
		let cancellable = null;
		if(data.baseUrl){
			let baseUrl = data.baseUrl;
			delete data.baseUrl;
			cancellable = self.Cancellable(
				axios.post(`${baseUrl}admin/${url}`, data, {
					headers: {
						'Content-Type': 'application/json',
					}
				})
			);
		}else{
			cancellable = self.Cancellable(
				axios.post(`${self.getUrl()}${url}`, data, {
					headers: {
						'Content-Type': 'application/json',
					}
				})
			);
		}
		cancellable.promise.then(res => {
			cb(null, res.data)
		}).catch(err => {
			if (err && !err.isCanceled) {
				cb(err)
			}
		});
		return cancellable;
	}


	patch = (url, data, cb) => {
		if(url.startsWith("/")){
			url = url.substr(1,url.length)
		}
		let self = this;
		self.pushRequest(url, data, "patch");
		let cancellable = self.Cancellable(
			axios.patch(`${this.getUrl()}${url}`, data)
		);
		cancellable.promise.then(res => {
			cb(null, res.data)
		}).catch(err => {
			if (err && !err.isCanceled) {
				cb(err)
			}
		});
		return cancellable;
	}



}

const getToken = () => {
	return Crypto.get('token');
}

const getBaseUrl = () => {
	const token = Crypto.get('token');
	if(!token)
		return "https://heimdall.eka.io/scanners";
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
	let json = JSON.parse(jsonPayload);
	if(!json.origin){
		return "https://heimdall.eka.io/scanners";
	}
	return json.origin + "scanners";
}

const uploadUrl = 'https://hive.eka.io/dice/scanner/upload'


const getUploadUrl = () => {
	const token = Crypto.get('token');
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
	let json = JSON.parse(jsonPayload);
	if(json.upload){
		return json.upload + "dice/employee/upload";
	}else{
		return 'https://hive.eka.io/dice/employee/upload'
	}
}

export {
	Wrapper,getBaseUrl,getToken,uploadUrl, getUploadUrl
}
