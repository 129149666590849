import React, {useEffect, useRef} from 'react';
import './Navigation.css'
import '..//Animation/Animation.css'
import {useHistory} from 'react-router-dom'

function Navigation({title, description, backUrl,children,top, chips=[], container='wrapper'}) {

    let HISTORY = useHistory();
    let NAVBAR = useRef()

    useEffect(() => {
            const wrapper = document.getElementById(container);
            if(wrapper){
                wrapper.addEventListener('scroll', animate, false);

            return ()=>{
                wrapper.removeEventListener('scroll', animate);
            }
        }
    // eslint-disable-next-line
    }, [container]);

    const animate = () => {
        if(NAVBAR && NAVBAR.current){
            const wrapper = document.getElementById(container);
            let top = wrapper.scrollTop;
            if(top>80){
                NAVBAR.current.className = 'navigator-m'
            } else if(top<20){
                NAVBAR.current.className = 'navigator-web'
            }
        }
    }

    return (
        <div ref={NAVBAR} style={{top}} className='FadeInRight-Effect navigator-web relative'>
             {
                chips.length?
                <div className='chips-wrapper'>
                    {
                        chips.map((chip, index)=>{
                            return (
                                <div key={index} className='flex align-center'>
                                    <div className={index===(chips.length-1)?'chips-active':'chips'}>
                                        {chip}
                                    </div>
                                    {
                                        index<(chips.length-1)&&
                                        <i style={{fontSize:'0.6rem'}} className="fas fa-chevron-right ml1 mr1"/>
                                    }
                                </div>
                            )
                        })
                    }
                </div>:<></>
             }
            <div className='title-wrapper'>
                {
                    backUrl&&
                    <div onClick={()=>{
                        if(backUrl==="back"){
                            HISTORY.go(-1)
                        }
                        else if(backUrl){
                            HISTORY.push(backUrl)
                        }else{
                            HISTORY.back()
                        }
                    }} className='back'>
                        <i className="fas fa-arrow-left"/>
                    </div>
                }
                <div>
                    <div className='title text-truncate'>
                        {title}
                    </div>
                    <div className='description text-muted text-truncate'>
                        {description}
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
}

export default Navigation;
