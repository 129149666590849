import React, {useEffect, useRef, useState} from 'react';
import { Modal, SelectInput, TOAST, StatelessTab, Input} from '../../../../retro';
import {get} from '../../../Network/Axios'

function TDSSelection({onSubmit, disabled, taxInformation}) {

    const [TDSOptions, setTDSOptions] = useState(undefined);
    const [ActiveTab, setActiveTab] = useState('Percentage');
    const [TDSModal, setTDSModal] = useState(undefined);
    let OPTION = useRef()

    useEffect(() => {
        getTDS()
    }, []);

    const getTDS = (params) => {
        get(`/config/tds`, (e, r) => {
			if (r) {
                setTDSOptions(r.tds)
			}else if(e){
                TOAST.handleError(e)
            }
		});
    }

    const updateValue = () => {
        let value = OPTION.current.value
        if(!value){
            TOAST.error('Enter a TDS Value')
            return
        }
        if(ActiveTab==='Percentage'){
            onSubmit('tds', Number(value))
        }else{
            onSubmit('tdsFixed', Number(value))
        }
        setTDSModal(undefined)
    }

    return (
        <>
        <div onClick={()=>{if(!disabled){setTDSModal(true)}}}>
            <Input disabled={disabled} morph={true} className='mr1 mt2' label='TDS' defaultValue={taxInformation.tds&&taxInformation.tds.toFixed(2)} type='number'/>
        </div>
        {
            TDSModal&&
            <Modal title={'Change TDS'} description='Update TDS for this invoice' button={'Update TDS'} onSubmit={updateValue} onClose={()=>setTDSModal(undefined)}>
                <div style={{marginTop:'-1rem'}}>
                    <StatelessTab style={{border:'none'}} active={ActiveTab} onClick={setActiveTab} tabs={['Percentage', 'Enter Value']}/>
                </div>
                {
                    ActiveTab==='Percentage'
                    ?<SelectInput ref={OPTION} label='Select TDS Percentage' options={TDSOptions.map(tds=>({label:`${tds.tax}% ${tds.description} (${tds.code})`, value:tds.id}))}/>
                    :<Input defaultValue={taxInformation.tds&&taxInformation.tds.toFixed(2)} type='number' ref={OPTION} label='Enter TDS Value' placeholder='TDS Value'/>
                }
            </Modal>
        }
        </>
    );
}

export default TDSSelection;