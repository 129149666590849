import React from 'react';
import { Tabs, Navigation } from '../../../../retro';
import { Switch, Route } from 'react-router-dom';
import Pending from './Pending'
import History from './History'

function Invoices() {

    return (
        <>
        <Navigation title='Approval Requests' backUrl='/' chips={['Scans']} />
        <Tabs background='var(--theme-bg-container)' top='3.87rem' tabs={[{path: '/approvals', title: <><i className="fas fa-inbox mr1"></i>Pending</>}, {path: '/approvals/history', title: <><i className="fas fa-history mr1"></i>History</>}]}/>
        <Switch>
            <Route path="/approvals/history" exact component={History}/>
            <Route path="/approvals" exact component={Pending}/>
        </Switch>
        </>
    );
}

export default Invoices;