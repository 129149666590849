import React, {useState, useEffect, useRef} from 'react';
import {TOAST, SidePane, StatelessTab, TabBody, Modal, Button, Input} from '../../../../../retro'
import {get} from '../../../../Network/Axios'
import DetailsTab from './Details'
import Inputs from './Inputs'
import Timeline from './Timeline'
import Comments from './Comments'
import Forms from './Forms'
import {post} from '../../../../Network/Axios'

function View({onClose, onReload, id, disabled}) {

    
    let FORM = useRef(), Remarks = useRef();
    const [ActiveTab, setActiveTab] = useState('Details');
    const [Loading, setLoading] = useState(true);
    const [BtnLoader, setBtnLoader] = useState(false);
    const [Details, setDetails] = useState({});

    const [DeclineModal, setDeclineModal] = useState(undefined);

    useEffect(() => {
        fetchTask(id)
    },[id])
    
    const fetchTask = (ID)=>{
		get(`workflow/${ID}/fetch`,(e,r)=>{
			if(r){
				setDetails(r);
                setLoading(false)
			}else if(e){
				TOAST.handleError(e)
			}
		})
	}

    const approve = (ID)=>{
        if(!FORM.current){
            TOAST.error('Fill the Form for Approval')
            return
        }
        
        let forms  = FORM.current()//Getting custom form values
        if(!forms){
            TOAST.error('Fill the Form for Approval')
            return
        }

        setBtnLoader(true)
        post(`/workflow/workflow/${ID}/approve`, {form:forms}, (e, r) => {
            if (r) {
                onReload()
                setBtnLoader(false)
                TOAST.success('Invoice  Approved')

            } else if (e) {
                TOAST.handleError(e)
            }
        });


    }

    const decline = (ID)=>{
        let remarks = Remarks.current.value;
        if(!remarks){
            TOAST.error('Remarks is required')
            return;
        }
        post(`/workflow/workflow/${ID}/decline`, {remarks}, (e, r) => {
			if (r) {
                onReload()
				TOAST.success('Request Declined')
			} else if (e) {
				TOAST.handleError(e)
			}
		});
    }

    return (
        <>
        <SidePane loading={Loading} title={Details.title} description={Details.description} onClose={onClose}
        preview={
           Details && Details.active && Details.forms && Details.forms.length?
            <div className='w-100 h-100'>
                <div className='w-100 h-100 relative overScroll' style={{padding:18,paddingBottom:'200px'}}>
                    <Forms forms={Details?Details.forms:[]} handleRef={func=>FORM.current=func}/>
                </div>
                <div className=' w-100 border-top pd2 flex center mt3 bg-fff' style={{position:' fixed', bottom:0, left:0}}>
                    <Button loading={BtnLoader} onClick={()=>approve(id)} className='approve-btn mr1 flex-fill' margin='mt0'><i className="fas fa-check"/> Approve</Button>
                    <Button onClick={()=>setDeclineModal(id)} className='decline-btn flex-fill' margin='mt0'><i className="fas fa-times"/> Decline</Button>
                </div>
            </div>:undefined
        }
        actions={
            Details.active  && ( !Details.forms || !Details.forms.length)?
            <div className='flex center'>
                <Button loading={BtnLoader} onClick={()=>approve(id)} className='approve-btn mr1 flex-fill' margin='mt1'><i className="fas fa-check"/> Approve</Button>
                <Button onClick={()=>setDeclineModal(id)} className='decline-btn flex-fill' margin='mt1'><i className="fas fa-times"/> Decline</Button>
            </div>
            :undefined
        }
        tabs={<StatelessTab style={{marginBottom:'-1rem', border:'none'}} active={ActiveTab} onClick={setActiveTab} top='3.87rem' tabs={['Details', 'Inputs', 'Comments', 'Timeline']}/>}>
            <TabBody active={ActiveTab} label='Details'>
                <DetailsTab disabled={disabled} onReload={()=>fetchTask(id)} id={id} task={Details}/>
            </TabBody>
            <TabBody active={ActiveTab} label='Inputs'>
                <Inputs inputs={Details.inputs}/>
            </TabBody>
            <TabBody active={ActiveTab} label='Comments'>
                <Comments comments={Details.comments} id={id} onReload={()=>fetchTask(id)}/>
            </TabBody>
            <TabBody active={ActiveTab} label='Timeline'>
                <Timeline timeline={Details.timelines}/>
            </TabBody>
        </SidePane>

        {DeclineModal&&
        <Modal title='Decline Approval Request' description='Are you sure you want to grant this approval request?' onSubmit={()=>decline(DeclineModal)} onClose={()=>setDeclineModal(undefined)} button='Decline'>
            <Input label='Remarks' placeholder='Enter Remarks' ref={Remarks}/>
        </Modal>
        }
        </>
    );
}

export default View;